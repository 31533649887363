import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AccountBalance, AccountCircle, Check, Home, MoneyOff, PlaylistAddCheck, WbIncandescent, Whatshot } from '@mui/icons-material';
import _ from "lodash";
import { submitConfirmData } from "../../actions/newGasContract";
import { i18n } from "../../config";
import Settings from "../../settings";
import Address from "../Address";
import CheckboxField from "../form-fields/CheckboxField";
import TextField from "../form-fields/TextField";


const Confirm = ({onSubmit, onGoBack}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const electricity = useSelector((state) => state.newElectricityContract);
  const gas = useSelector((state) => state.newGasContract);

  const handleSubmit = async (values) => {
    dispatch(submitConfirmData(values));

    if (onSubmit) {
      await onSubmit();
    }
  };

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  const theme = useTheme();
  const matchesBreakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const lateralPageMargins = matchesBreakpointXsDown ? 0 : 50;

  return (
    <>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box display="flex" alignItems="center" sx={{m: 2, ml: 0}}>
          <Check fontSize="large" color="secondary" sx={{mr: 1}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_confirmation')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          promotionalCode: electricity.promotionalCode,
          acceptOVConditions: electricity.acceptOVConditions,
          acceptGdpr: electricity.acceptGdpr,
        }}
        validate={(values) => {
          const errors = {};

          if (!isAuthenticated) {
            if (!values.acceptOVConditions) {
              _.set(errors, "acceptOVConditions", i18n.t('common:text.required_field'));
            }
            if (!values.acceptGdpr) {
              _.set(errors, "acceptGdpr", i18n.t('common:text.required_field'));
            }
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}}>
                <Home fontSize="medium" color="secondary" style={{padding: 10}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {i18n.t('common:text.contractation_about_home')}
                </Typography>
              </Box>

              <Grid container style={{marginLeft: lateralPageMargins, marginRight: lateralPageMargins}}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_supply_type_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1">
                    {i18n.t(`common:text.contractation_${electricity.residenceType}_supply`)}
                  </Typography>
                </Grid>
                {!_.isEmpty(electricity.address) &&
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_address_validation')}
                      </Typography>
                    </Grid>
                      <Grid item xs={12} sm={9} md={10}>
                      <Address readonly value={electricity.address} />
                    </Grid>
                  </Grid>
                }
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cnae_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {_.get(electricity, "cnae.descripcio", "?")}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}}>
                <AccountCircle fontSize="medium" color="secondary" style={{padding: 10}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {i18n.t('common:text.contractation_about')}{" "}
                  {electricity.company
                    ? i18n.t('common:text.contractation_enterprise')
                    : i18n.t('common:text.contractation_you')}
                </Typography>
              </Box>

              <Grid container style={{marginLeft: lateralPageMargins}}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {!electricity.company && i18n.t('common:text.contractation_name_validation')}
                    {electricity.company && i18n.t('common:text.contractation_company_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {!electricity.company && <>{electricity.surName1} {electricity.surName2}, {electricity.name}</>}
                    {electricity.company && electricity.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_dni_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {_.get(electricity, "vat", "").replace("ES", "")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_mobile_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {electricity.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_email_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {electricity.email}
                  </Typography>
                </Grid>

                {!_.isEmpty(electricity.invoiceAddress) &&
                  <Address readonly value={electricity.invoiceAddress} />}

                {electricity.company &&
                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_legal_representative')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {electricity.surName1Representante}{" "}
                        {electricity.surName2Representante}, {electricity.nameRepresentante}{" "}
                        {electricity.vatRepresentante.replace("ES", "")}
                      </Typography>
                    </Grid>
                  </>
                }
              </Grid>
            </Box>


            {electricity.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <WbIncandescent fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_about_contract')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {electricity.cups}
                    </Typography>
                  </Grid>

                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_tariff')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {electricity.selectedProduct.name}
                      </Typography>
                    </Grid>
                  </>
                </Grid>
              </Box>
            }

            {gas.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <Whatshot fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_about_gas_contract')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {gas.cups}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_tariff')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1">
                      {gas.selectedProduct.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }
            
            {props.electricity.iban &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <AccountBalance fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_about_payment')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_iban_direct_debit')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="body1">
                      {props.electricity.iban}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }

            <Grid container style={{marginLeft: lateralPageMargins}}>
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" style={{fontWeight: "bold"}}>
                  {i18n.t('common:text.contractation_iban_direct_debit')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography variant="body1">
                  {electricity.iban}
                </Typography>
              </Grid>

            </Grid>

            {Settings?.features?.promotionalCode &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <MoneyOff fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_promotional_code_applied_title')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_promotional_code')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {props.electricity.promotionalCode.map(promocode => <p>{promocode}</p>)}
                  </Grid>
                </Grid>
              </Box>
            }

            {!isAuthenticated &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <PlaylistAddCheck fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_conditions')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12}>
                    <Field
                      name="acceptOVConditions"
                      label={i18n.t('common:text.contractation_ov_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.conditions", "")}
                      rel="noreferrer"
                    >
                      {i18n.t('common:text.contractation_ov_conditions')}
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="acceptGdpr"
                      label={i18n.t('common:text.contractation_privacy_policy_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.gdpr", "")}
                      rel="noreferrer"
                    >
                      {i18n.t('common:text.contractation_privacy_policy')}
                    </a>
                  </Grid>
                </Grid>
              </Box>
            }

            <Divider style={{marginTop: 20, marginBottom: 20}}/>
            <Button
              variant={'text'}
              onClick={() => handleGoBack(values)}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {i18n.t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color={'primary'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.contractation_send')}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default Confirm;