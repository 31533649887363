import { 
  CircularProgress, Button, Divider, Grid, Box, Typography, Paper, 
  Link, RadioGroup, FormControlLabel, Radio, FormHelperText, FormLabel
} from "@mui/material"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeIcon from '@mui/icons-material/Home';
import _ from "lodash";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import * as newElecContractActions from "../../actions/newElectricityContract";
import { i18n } from "../../config";
import Settings from "../../settings";
import FlexRow from "../FlexRow";
import RichContent from "../RichContent";
import AddressField, {
  validarReferenciaCatastral,
} from "../form-fields/AddressField";
import CNAEField from "../form-fields/CNAEField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import CheckboxField from "../form-fields/CheckboxField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import CurrentActiveContract from "../form-fields/CurrentActiveContract";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import ResidenceTypeSelect from "../form-fields/ResidenceTypeSelect";


const FormAboutHome = ({ selectComerOrigin, gas, onSubmit }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const elecNewContract = useSelector((state) => state.newElectricityContract);
  const [comerOriginEnabled, setComerOriginEnabled] = useState(selectComerOrigin);

  const handleSubmit = async (values) => {
    await onSubmit(values)
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleUpdateResidenceType = (values) => {
    dispatch(newElecContractActions.updateResidenceType(values));
  };

  const handleUpdateUploadedFiles = (values) => {
    dispatch(newElecContractActions.updateUploadedFiles(values));
  };

  const handleUpdateActiveContract = (values) => {
    dispatch(newElecContractActions.setActiveContract(values));
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value) {
      dispatch(newElecContractActions.storeCUPSData(value));
      if (cupsValid(value)) {
        dispatch(newElecContractActions.validateCUPSAvailability(values, true));
      }
    }
  };


  // Set leads consent to true by default if user is authenticated
  let leadsConsent = isAuthenticated;

  const leadsNote = (
    <RichContent
      content={i18n.t('common:text.contractation_leads_note', {
        client: _.get(Settings, "organization.name", ""),
        privacyPolicy: _.get(Settings, "links.privacyPolicy", "")
      })}
    />
  );

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  // With keepDirtyOnReinitialize we avoid the re-initialization of values problem in initialValues:
  // https://stackoverflow.com/questions/54635276/react-final-form-set-initialvalues-from-props-form-state-resets-on-props-chang
  // https://final-form.org/docs/final-form/types/Config#keepdirtyonreinitialize
  // This is a temporal solution. A permanent one would be using ReduxForm or avoiding initialsValues altogether.

  return (
    <div>
      {_.get(Settings, "newContract.sectionHeaders", true) &&
        <Box display="flex" alignItems="center" sx={{m: 2, ml: 0}}>
          <HomeIcon fontSize="large" color="secondary" sx={{mr: 1}}/>
          <Typography variant="h4" style={{ alignSelf: "center" }}>{i18n.t('common:text.contractation_about_home')}</Typography>
        </Box>
      }
      <Form
        onSubmit={(values) => handleSubmit(values).then()}
        initialValues={{
          leadsConsent: leadsConsent || elecNewContract.leadsConsent,
          cups: elecNewContract.cups,
          address: elecNewContract.address,
          residenceType: elecNewContract.residenceType,
          cnae: elecNewContract.cnae,
          invoice: elecNewContract.invoice,
          comerOrigin: elecNewContract.comerOrigin,
          activeContract: elecNewContract.activeContract,
          acceptMorosityVerification: false,
        }}
        keepDirtyOnReinitialize={true}
        validate={(values) => {
          const errors = {};

          if (_.get(Settings, 'newContract.requireLeadConsent', false) && !values.leadsConsent) {
            _.set(errors, "leadsConsent", i18n.t('common:text.required_field'));
          }

          if (!values.cups) {
            errors.cups = i18n.t('common:text.required_field');
          } else if (!cupsValid(values.cups)) {
            errors.cups = i18n.t('common:text.contractation_cups_not_valid');
          }

          if (!_.get(values, "address.zipcode")) {
            _.set(errors, "address.zipcode", i18n.t('common:text.required_field'));
          } else if (values.address.zipcode.length !== 5) {
            _.set(
              errors,
              "address.zipcode",
              i18n.t('common:text.contractation_postal_code_length')
            );
          }

          // City
          if (_.isEmpty(_.get(values, "address.city", {}))) {
            _.set(errors, "address.city", i18n.t('common:text.required_field'));
          }

          // State
          if (_.isEmpty(_.get(values, "address.state"))) {
            _.set(errors, "address.state", i18n.t('common:text.required_field'));
          }

          // Street Type
          if (_.isEmpty(_.get(values, "address.tv"))) {
            _.set(errors, "address.tv", i18n.t('common:text.required_field'));
          }

          // Street Name
          if (!_.get(values, "address.nv")) {
            _.set(errors, "address.nv", i18n.t('common:text.required_field'));
          } else if (values.address.nv.length >= 256) {
            _.set(errors, "address.nv", i18n.t('common:text.address_validation_nv_length'));
          }

          // Street Number
          if (!_.get(values, "address.pnp")) {
            _.set(errors, "address.pnp", i18n.t('common:text.required_field'));
          } else if (values.address.pnp.length >= 9) {
            _.set(errors, "address.pnp", i18n.t('common:text.address_validation_pnp_length'));
          }

          // Block
          if (_.get(values, "address.bq") && values.address.bq.length >= 4) {
            _.set(errors, "address.bq", i18n.t('common:text.address_validation_bq_length'));
          }

          // Stair
          if (_.get(values, "address.es") && values.address.es.length >= 4) {
            _.set(errors, "address.es", i18n.t('common:text.address_validation_es_length'));
          }

          // Floor
          if (_.get(values, "address.pt") && values.address.pt.length >= 10) {
            _.set(errors, "address.pt", i18n.t('common:text.address_validation_pt_length'));
          }

          // Door
          if (_.get(values, "address.pu") && values.address.pu.length >= 4) {
            _.set(errors, "address.pu", i18n.t('common:text.address_validation_pu_length'));
          }

          // Referencia Catastral
          if (_.get(values, "address.ref_catastral")) {
            if (
              validarReferenciaCatastral(values.address.ref_catastral) !== 1
            ) {
              _.set(
                errors,
                "address.ref_catastral",
                i18n.t('common:text.contractation_not_valid_ref')
              );
            }
          }

          // Subministrament d'empresa & CNAE
          if ((_.get(values, 'residenceType') === 'business') && !_.get(values, "cnae")) {
            _.set(errors, "cnae", i18n.t('common:text.required_field'));
          }

          // Active Contract (A3)
          if (_.isNull(_.get(values, 'activeContract'))) {
            _.set(errors, "activeContract", i18n.t('common:text.required_field'));
          }

          // Tipus de subministrament
          if (_.isNull(_.get(values, 'residenceType'))) {
            _.set(errors, "residenceType", i18n.t('common:text.required_field'));
          }

          // Comer Origin
          if (comerOriginEnabled && !_.get(values, "comerOrigin")) {
            _.set(errors, "comerOrigin", i18n.t('common:text.required_field'));
          }
          let minFiles = _.get(Settings, 'newContract.invoiceRequired', false) ? 1 : 0;
          if (_.get('values', 'activeContract') === 'no') {
            minFiles = 0;
          }

          const fileErrors = validateFileUpload(values.invoice, {
            min: minFiles,
            minMessage: i18n.t('common:text.multiupload_validation_min'),
          });
          if (fileErrors) {
            _.set(errors, "invoice", fileErrors);
          }

          // Accept Morosity Verification
          if (_.get(Settings, "newContract.mustAcceptMorosityVerification", false)) {
            if (!_.get(values, "acceptMorosityVerification")) {
              _.set(errors, "acceptMorosityVerification", i18n.t('common:text.required_field'));
            }
          }

          return Object.keys(errors).length
            ? errors
            : true
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0}>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_about_home')}
                </Typography>
                <Typography variant="body2">
                  {i18n.t('common:text.contractacion_about_home_info')}
                </Typography>
              </Grid>
            </Grid>
            <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_cups_title')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="cups"
                    label={gas ? i18n.t('common:text.contractation_cups_electricity') : i18n.t('common:text.contractation_cups')}
                    component={CUPSField}
                    style={{ width: "100%" }}
                    onUpdate={(value) => handleCUPSUpdate({ ...values, cups: value })}
                    cupsAvailability={elecNewContract.cupsAvailability}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="helper-text">{i18n.t('common:text.contractation_cups_helper')}</div>
                </Grid>
              </Grid>
              {/* <Divider style={{marginBottom: 20, marginTop: 20}}/> */}
              <Field name="address" component={AddressField}
                showMobile={_.get(Settings, 'newContract.showMobile', false)} showEmail={_.get(Settings, 'newContract.showEmail', false)} />
              {Settings?.newContract?.showMobile || Settings?.newContract?.showEmail &&
                <Divider style={{ marginBottom: 20, marginTop: 20 }} />
              }
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_housing_consumption')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={!(_.get(values, 'residenceType') === 'business') ? 6 : 12}>
                  <Field
                    name="activeContract"
                    component={CurrentActiveContract}
                    onUpdate={handleUpdateActiveContract}
                  />
                  <Grid container spacing={matchesBreakpointMd ? 3 : 2}>
                    <Grid item xs={12} md={!(_.get(values, 'residenceType') === 'business') ? 12 : 4}>
                      <Field
                        name="residenceType"
                        component={ResidenceTypeSelect}
                        onUpdate={handleUpdateResidenceType}
                      />
                    </Grid>
                    {(_.get(values, 'residenceType') === 'business') &&
                      <Grid item xs={12} md={8}>
                        <Field
                          name="cnae"
                          component={CNAEField}
                        />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              {comerOriginEnabled &&
                <Grid item xs={12} md={6} style={{ marginBottom: 20, marginTop: 20 }}>
                  <Field
                    name="comerOrigin"
                    component={ComerOriginSelectField}
                    onAvailableComersUpdate={handleAvailableComersUpdate}
                  />
                </Grid>
              }
              {values.activeContract === 'yes' &&
                <>
                  <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_last_invoice')}
                  </Typography>
                  <Paper style={{ padding: 15 }} variant="outlined">
                    <FlexRow>
                      <Field
                        name="invoice"
                        component={FileUploadField}
                        min={1}
                        max={3}
                        label={
                          i18n.t('common:text.contractation_last_invoice')
                          + `${_.get(Settings, 'newContract.invoiceRequired', false) ? "*" : ""}`
                        }
                        hint={i18n.t('common:text.contractation_last_invoice_helper')}
                        anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                        removeLabel={i18n.t('common:text.removorosity Verificatione')}
                        onUpdate={handleUpdateUploadedFiles}
                      />
                    </FlexRow>
                  </Paper>
                </>
              }
              {
                (_.get(Settings, "newContract.mustAcceptMorosityVerification", false)) && (
                  <Box mt={5}>
                    <Field
                       name="acceptMorosityVerification"
                       label="imbecil"
                       render={({input, label, meta, ref, style}) => {
                         return (
                           <Box>
                             <FormLabel>{i18n.t("common:text.contractation_must_grant_morosity_verification_p1")}</FormLabel>

                             <RadioGroup
                               defaultValue={Boolean(input.value)}
                               value={Boolean(input.value)}
                               {...input}
                               ref={ref}
                               onChange={(event, value) => input.onChange(value==="true")}
                               name="authorization-radio-group"
                               style={{marginBottom: "8px"}}
                             >
                               <FormControlLabel value={true} control={<Radio/>} label={i18n.t("common:text.contractation_authorize_grant_morosity_verification")}/>
                               <FormControlLabel value={false} control={<Radio />} label={i18n.t("common:text.contractation_unauthorize_grant_morosity_verification")}/>
                             </RadioGroup>
                             { meta.error && meta.touched &&
                               <FormHelperText error={true} >
                                 {meta.error}
                               </FormHelperText>
                             }
                             <FormLabel>
                                { i18n.t("common:text.contractation_must_grant_morosity_verification_p2") }
                                {
                                  i18n.t("common:text.contractation_must_grant_morosity_verification_link") && (
                                    <Link color="primary" href={i18n.t("common:text.contractation_must_grant_morosity_verification_link")} target="_blank">
                                      { i18n.t("common:text.contractation_must_grant_morosity_verification_link_text") }
                                    </Link>
                                  )
                                }
                               
                              </FormLabel>
                           </Box>
                         )
                       }}
                     />
                   </Box>
                )
              }

            </Box>
            {_.get(Settings, "features.leads", false) && (
              <div className="helper-text" style={{ marginBottom: 20, marginTop: 25 }}>
                {isAuthenticated ? (
                  leadsNote
                ) : (
                  <Field
                    name="leadsConsent"
                    label={leadsNote}
                    component={CheckboxField}
                  />
                )}
              </div>
            )}

            <div style={{ marginTop: 25 }}>
              <Button
                variant={'contained'}
                color={'primary'}
                type="submit"
                disabled={submitting || validating || (elecNewContract.cupsAvailability.invalid !== null)}
              >
                {
                  submitting || validating ? (
                    <CircularProgress size={25} style={{ marginRight: 5 }} />
                  ) : null
                }
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default FormAboutHome;
