import React from 'react';
import { Publish, Attachment, Clear } from '@mui/icons-material';
import { Button } from '@gisce/oficina-virtual-components';
import {red} from '@mui/material/colors';

class UploadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.value
    };
  }

  handleOnFileLoad = (e) => {
    let reader = new FileReader();
    const targetFile = e.target.files[0];
    const size = targetFile.size;
    reader.onloadend = () => {
      const file = {
        name: targetFile.name,
        content: reader.result
      };
      this.setState({file});
      if (this.props.onChange) {
        this.props.onChange(file, size);
      }
    }
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }

  };

  handleClearFile = () => {
    this.setState({
      file: null
    });
    if (this.props.onChange) {
      this.props.onChange(null);
    }
  };

  render() {

    const {file} = this.state;
    const {errorText} = this.props;

    return (
      <div style={{...this.props.style, ...{display: 'flex', flexWrap: 'wrap'}}}>
        <Button 
          variant={'text'}
          component={'label'}
        >
          {file?<Attachment/>:this.props.icon}
          {
            <div style={{marginRight: 5, marginLeft: 5}}>
              {this.props.label}
            </div>
          } 
          <input type="file" style={{display: "none"}}  onChange={this.handleOnFileLoad} accept={this.props.accept}/>
        </Button>
        {file &&
          <div style={{display: 'flex'}}>
            <div style={{padding: '8px'}}>{file.name}</div>
            <Button  onClick={this.handleClearFile}>
              {<Clear/>}
            </Button>
          </div>
          }
            {errorText &&
            <div style={{padding: '8px', color: red[500]}}>{errorText}</div>
            }
      </div>
    );
  }
}

export default UploadButton;

UploadButton.defaultProps = {
  file: null,
  label: 'Upload',
  icon: <Publish/>,
};
