import React from "react";
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';


const EurosField = ({field: input, ...rest}) => {
  return (
    <NumericFormat
        style={{width: '100%', maxWidth: '300px'}}
        customInput={TextField}
        suffix=" €"
        allowedDecimalSeparators={[',', '.']}
        decimalSeparator=","
        thousandSeparator=" "
        valueIsNumericString={true}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        onValueChange={({ floatValue }) => input.onChange(floatValue)}
        {...rest}
      />
  )
}

export default EurosField;