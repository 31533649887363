import React from "react";
import { TextField } from '@mui/material';
import { NumericFormat } from "react-number-format";

const EnergyConsumptionField = ({field: input, ...rest}) => {
  return (
    <NumericFormat
      customInput={TextField}
      suffix=" kWh"
      type="text"
      allowedDecimalSeparators={[",", "."]}
      decimalSeparator=","
      thousandSeparator=" "
      valueIsNumericString={true}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      value={input.value}
      onValueChange={({ floatValue }) => input.onChange(floatValue)}
      {...rest}
    />
  );
};

export default EnergyConsumptionField;
