import { useEffect, useState } from "react";
import * as invoicesMultipointActionCreators from "../../actions/invoicesMultipoint";
import _ from "lodash";
import { Box, Switch, Typography, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InvoicesChart from "./InvoicesChart";
import InvoicesTable from "./InvoicesMultipointTable";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesPagination from "./InvoicesPagination";
import { ContentHeader } from "../ContentHeader";
import { useParams } from "react-router-dom";
import Settings from "../../settings";

const InvoicesMultipointContent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const invoices = useSelector(state => state.invoicesMultipoint.items);
  const itemsOffset = useSelector(state => state.invoicesMultipoint.itemsOffset);
  const itemsPage = useSelector(state => state.invoicesMultipoint.itemsPage);
  const loaded = useSelector(state => state.invoicesMultipoint.loaded);
  const errors = useSelector(state => state.invoicesMultipoint.errors);
  const currentFilter = useSelector((state) => state.invoicesMultipoint.currentFilter);
  const token = useSelector(state => state.auth.token);

  const [initialFilter, setInitialFilter] = useState([]);
  const [initialItemsPage, setInitialItemsPage] = useState();

  useEffect(() => { //Runs when component is mounted/unmounted
    dispatch(invoicesMultipointActionCreators.fetchFilters(token));
  }, []);

  useEffect(() => {  //Runs when the url parameter 'contractName' changes
    if (params?.contractName) {
      setInitialFilter([
        {
          category: { type: "autocomplete", renderText: "Contracte", searchField: "polissa_id.name" },
          value: [params?.contractName], 
          type: 'contains'
        }
      ]);
      dispatch(invoicesMultipointActionCreators.fetchInvoicesMultipoint(token, false, `('polissa_id.name', 'in', ['${params.contractName}'])`));
    } else {
      setInitialFilter([]);
      dispatch(invoicesMultipointActionCreators.fetchInvoicesMultipoint(token, false, ''));
    }
    setInitialItemsPage(null);
  }, [params?.contractName]);

  const fetchData = () => {
    dispatch(invoicesMultipointActionCreators.fetchInvoicesMultipoint(token, false, currentFilter, itemsOffset, itemsPage));
  };

  const applySearchFilter = (newFilters, newItemsPage) => {
    const newItemsOffset = newItemsPage !== itemsPage ? 0 : itemsOffset;
    setInitialItemsPage(newItemsPage);
    dispatch(invoicesMultipointActionCreators.fetchInvoicesMultipoint(token, false, newFilters, newItemsOffset, newItemsPage));
  };

  const handleItemsOffset = (newOffset) => {
    dispatch(invoicesMultipointActionCreators.fetchInvoicesMultipoint(token, false, currentFilter, newOffset, itemsPage));
  };

  return (
    <div style={{ marginTop: 20 }}>
      <ContentHeader
        title={t('common:text.invoices_view_title')}
        addButton={false}
        refreshButton={true}
        refreshClickMethod={fetchData}
      />

      {!errors ? 
        <div>
          <Box mb={2} ml={1}>
            <Typography variant={"h6"}>
              {t('common:text.invoices_contracts_chart')}
            </Typography>
          </Box>

          {/* <div style={{ marginBottom: 50 }}>
            <InvoicesChart invoices={invoices} />
          </div> */}

          {loaded && invoices?.length === 0 &&
            <Box mb={2} ml={1}>
              <Typography variant={"h5"}>
                {t('common:text.invoices_view_empty_list')}
              </Typography>
            </Box>
          }

          <div style={{ marginBottom: 50 }}>
            <InvoicesFilter 
              initialFilters={initialFilter} 
              initialItemsPage={initialItemsPage}
              applySearchFilter={applySearchFilter}
            />
          </div>

          <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type={"Multipoint"}/>

          <InvoicesTable invoices={invoices} />

          <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type={"Multipoint"}/>

        </div>
      :
        <Alert severity="warning">{t("common:text.invoices_error_fetching")}</Alert>
      }
        
    </div>
  );
}

export default InvoicesMultipointContent;
