import useI18n from "@/hooks/useI18n";
import { ListGroup, ListItem } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../../settings";
import { useSelector } from "react-redux";
import {
	MapsHomeWork,
  Feedback,
  PersonAdd,
  VpnKey,
	PermIdentity,
	Receipt,
	Person,
  AddBox,
  Message,
	AutoGraph,
	Savings,
	AddHomeWork,
	CropDin
} from "@mui/icons-material";
import { AltHomeMainListItems } from "./AltHomeMainListItems";
import { RootState } from "@/store";
import { contraction_url } from "@/constants/i18n";
import DinamicServerNavigation from "@/components/ServerElements/DinamicServerNavigation";

export const MainListItems = () => {
	const { t } = useTranslation();
	const { getContractationURL } = useI18n();
	const navigate = useNavigate();
	const location = useLocation();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const newCustomer = useSelector((state: RootState) => state.auth.newCustomer);
	
	const navigateChangePassword = () => {
		if (Settings.features?.oidc) {
			window.location.href = Settings.oidc.changePasswordUrl;
		} else {
			navigate(t('common:url.changePassword'))
		}
	}

	const detailsButtons = () => {
		return <>
			<ListItem 
					icon={<MapsHomeWork />}
					label={t('common:text.contracts_title')}
					onClick={() => navigate(t('common:url.contracts'))}
					selected={location.pathname == t('common:url.contracts')}
				/>
				<ListItem 
					icon={<Receipt />}
					label={t('common:text.invoices_title')}
					onClick={() => navigate(t('common:url.invoices'))}
					selected={location.pathname == t('common:url.invoices')}
				/>
		</>
	}

	const openIdButtons = () => {
		return !newCustomer &&
			<>
				<ListItem 
					icon={<MapsHomeWork />}
					label={t('common:text.contracts_title')}
					onClick={() => navigate(t('common:url.contracts'))}
					selected={location.pathname == t('common:url.contracts')}
				/>
				<ListItem 
					icon={<Receipt />}
					label={t('common:text.invoices_title')}
					onClick={() => navigate(t('common:url.invoices'))}
					selected={location.pathname == t('common:url.invoices')}
				/>
			</>			
	}

	const ProfileListGroup = <ListGroup 
		icon={<PermIdentity />}
		titleLabel={t('common:text.altHome_my_profile')}
		startOpen={location.pathname == t('common:url.changePassword')}
	>
		<ListItem 
			icon={<VpnKey />}
			label={t('common:text.change_password_view_title')}
			onClick={() => navigateChangePassword()}
			selected={location.pathname == t('common:url.changePassword')}
		/>
	</ListGroup>

	return <>
		{Settings?.altHome?.sideBar && <AltHomeMainListItems />}

		{!isAuthenticated && <>
			{<ListItem
				icon={<Person />}
				label={t('common:text.login_title')}
				onClick={() => navigate(t('common:url.login'))}
				selected={location.pathname == t('common:url.login')}
			/>}
			{!Settings.features?.oidc && Settings.features?.signup && <ListItem
				icon={<PersonAdd />}
				label={t('common:text.login_view_button_activate')}
				onClick={() => navigate(t('common:url.activate'))}
				selected={location.pathname == t('common:url.activate')}
				/>}
			{!Settings.features?.oidc && Settings.features?.sidebarContract && <ListItem
				icon={<AddBox />}
				label={t('common:text.new_customer_title')}
				onClick={() => navigate(t('common:url.changePassword'))}
				selected={location.pathname == getContractationURL()}
				/>}
			{Settings.features?.unsignedContact && 
				Settings.features?.contact && <ListItem 
				icon={<Message />}
				label={t('common:text.contact_title')}
				onClick={() => navigate(t('common:url.unsigned_contact'))}
				selected={location.pathname == t('common:url.unsigned_contact')}
				/>}
			{Settings?.comparator?.enabled && <ListItem 
				icon={<Savings />}
				label={t('common:text.menu_price_comparator_title')}
				onClick={() => navigate(t('common:url.priceComparator'))}
				selected={location.pathname == t('common:url.priceComparator')}
				/>}
			{Settings?.powerCalculator?.enabled && <ListItem 
				icon={<AutoGraph />}
				label={t('common:text.menu_power_calculator_title')}
				onClick={() => navigate(t('common:url.powerCalculator'))}
				selected={location.pathname == t('common:url.powerCalculator')}
				/>}
		</>}

		{isAuthenticated &&
			<>
				{!Settings?.altHome?.sideBar && <>
					{Settings.features?.oidc && openIdButtons()}
					{!Settings.features?.oidc && detailsButtons()}
				</>}

				{Settings.features?.newContract && <ListItem 
						icon={<AddHomeWork />}
						label={t('common:text.contractation_button_title')}
						onClick={() => navigate(t(contraction_url))}
						selected={location.pathname == t(contraction_url)}
					/>
				}

				{Settings.features?.enableServerElements && 
					<DinamicServerNavigation 
						clickableComponent={({children, linkInfo, onClick, ...props}) => {
							return ( 
								<ListItem 
									label={linkInfo.btnTitle}
									selected={location.pathname.includes(linkInfo.routePath)}
									icon={<CropDin />}
									onClick={onClick}
								/>
							)
						}}
					/>
				}

				{ProfileListGroup}
				{Settings.features?.reclamATCMenu && <ListItem
					icon={<Feedback />}
					label={t('common:text.reclamations_title')}
					onClick={() => navigate(t('common:url.reclamations'))}
					selected={location.pathname == t('common:url.reclamations')}
				/>}
				{Settings.features?.contact && <ListItem
					icon={<PersonAdd />}
					label={t('common:text.contact_title')}
					onClick={() => navigate(t('common:url.contact'))}
					selected={location.pathname == t('common:url.contact')}
				/>}
			</>
		}

	</>
}
