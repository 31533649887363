import React from "react";
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

export const powerValid = (value) => {
  return value && !Number.isNaN(Number(value)) && Number(value) > 0;
};

const PowerField = ({field: input, ...rest}) => {
  return (
    <NumericFormat
      style={{ width: "100%", maxWidth: "300px" }}
      customInput={TextField}
      suffix=" kW"
      allowedDecimalSeparators={[",", "."]}
      decimalSeparator=","
      thousandSeparator=" "
      valueIsNumericString={true}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      value={input.value}
      onValueChange={({ floatValue }) => input.onChange(floatValue)}
      {...rest}
    />
  );
}

export default PowerField;