import { SmartTable } from '@gisce/oficina-virtual-components'
import { LoadingAnimation } from "../LoadingAnimation";
import { ErrorOutline } from '@mui/icons-material';
import { useSelector } from "react-redux";
import useInvoiceTable from './useInvoiceTable';


const InvoicesTable = ({ invoices }) => {
  const { tableHead, computeRow, generateTableSkeleton } = useInvoiceTable();
  const loaded = useSelector((state) => state.invoices.loaded);

  let contentRows;

  if (!loaded) {
    // S'ha de fer l'equivalent del computeRow
    contentRows = generateTableSkeleton(3);
  } else {
    contentRows = invoices?.map(invoice => computeRow(invoice));
  }

  const getInvoiceStatus = (item) => {
    if (item?.amount_debt) {
      return <ErrorOutline style={{ color: '#FF0000' }} />
    }
  }
  
  return (
    <SmartTable
      columns={tableHead}
      dataSource={contentRows ?? []}
      loading={false}
      onRowStatus={getInvoiceStatus}
      loadingComponent={contentRows}
    />
  )
}

export default InvoicesTable;