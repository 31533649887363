import React from "react";

import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import FloatingSearchButton from "../FloatingSearchButton";

const ContentHeader = (props) => {
  const title = props.title;
  const withAdd = props.addButton ? props.addButton : false;
  const withRefresh = props.refreshButton ? props.refreshButton : false;

  const withButton = withAdd || withRefresh;

  const handleAdd = props.addClickMethod ? props.addClickMethod : null;
  const handleRefresh = props.refreshClickMethod
    ? props.refreshClickMethod
    : null;

  // The Refresh Button
  const refreshButton = withRefresh ? (
    <Fab
      color="primary"
      aria-label="refresh"
      onClick={handleRefresh}
      style={{
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      <RefreshIcon />
    </Fab>
  ) : null;

  // The Add Button
  const addButton = withAdd ? (
    <Fab
      color="primary"
      aria-label="add"
      onClick={handleAdd}
      style={{
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      <AddIcon />
    </Fab>
  ) : null;

  const handleClickSearch = () => {};

  const searchButton = props.onSearch ? (
    <FloatingSearchButton
      onClick={handleClickSearch}
      onSearch={props.onSearch}
    />
  ) : null;

  return (
    <Box sx={{m: 1}}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={6}>
          <Box>
            <Typography variant={'h4'}>
                {title}
            </Typography>
          </Box>
        </Grid>
        {withButton && (
          <Grid item xs={6} style={{textAlign: "right"}}>
            {searchButton}
            {refreshButton}
            {addButton}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export { ContentHeader };
