import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import { CircularProgress, Button, Box, Typography, Grid } from "@mui/material"

import { i18n } from "../../config";

import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import GasProductSelectField from "../form-fields/GasProductSelectField";

import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutContract = (props) => {

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitAboutContractData({ ...values, changeOwner: props.changeOwner });

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleAccessTariffSelectUpdate = async (value) => {
    props.setAccessTariff(value);
    let accessTariff = value;
    props.fetchAvailableGasProducts(props.cups, accessTariff);
  };

  return (
    <div>
      { _.get(Settings, "newGasContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_about_gas_contract')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          accessTariff: props.accessTariff,
          selectedProduct: props.selectedProduct,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.accessTariff) {
            errors.accessTariff = i18n.t('common:text.required_field');
          }
          if (!values.selectedProduct) {
            errors.selectedProduct = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {(!props.sipsData || !props.accessTariff) && (
                  <Field
                    name="accessTariff"
                    component={AccessTariffSelectField}
                    gas={true}
                    onSelectUpdate={handleAccessTariffSelectUpdate}
                    style={{width: 300}}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {props.accessTariff && (
                  <Field name="selectedProduct" component={GasProductSelectField} />
                )}
              </Grid>
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Button
                variant={'text'}
                onClick={handleGoBack}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutContract);
