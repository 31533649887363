import { FormHelperText, TextField } from '@mui/material';
import { NumericFormat }  from 'react-number-format';

const PeopleField = ({input, meta, ...rest}) => {
  
  return (
    <>
      <NumericFormat
        style={{width: '100%', maxWidth: '300px'}}
        customInput={TextField}
        allowNegative={false}
        allowedDecimalSeparators={[",", "."]}
        decimalScale={0}
        decimalSeparator=","
        thousandSeparator="." 
        valueIsNumericString={true}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        onValueChange={({ floatValue }) => input.onChange(floatValue)}
        {...rest}
      />
      {meta.touched && meta?.error && 
        <FormHelperText error={true} id="area-field-error-text">
          {meta.error}
        </FormHelperText>
      }
    </>
  )
}

export default PeopleField;