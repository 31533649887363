import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contractActionCreators from "../../actions/contracts";
import * as contractActionCreatorsGas from "../../actions/contractsGas";
import validate from "validate.js";
import  IBANField, { IBANvalid } from "../IBANField";
import {LoadingAnimation} from "../LoadingAnimation";
import {data_create_api_resource} from "../../utils/http_functions";
import SignatureProcess from "../SignatureProcess";
import ErrorMessage from "../ErrorMessage";
import Settings, {features} from "../../settings";

import { red } from '@mui/material/colors';
import {
  CircularProgress, Button, Checkbox, Select,
  FormControlLabel, Radio, RadioGroup, MenuItem,
  FormControl, InputLabel, FormGroup, Grid,
  Typography,
} from '@mui/material';
import { i18n } from "../../config";
import MultiUploadButton from "../MultiUploadButton";
import Banner from "../Banner/Banner";

const styles = {
  ibanForm: {
    width: '33%'
  }
}

const mapStateToProps = (state) => {
  return {
    loadingIbans: state.ibans.loadingIbans,
    availableIbans: state.ibans.ibans || [],
    token: state.auth.token,
    contract: state.contracts.view_contract || state.contractsGas.items[state.contractsGas.contractId],
  }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
        contract: bindActionCreators(contractActionCreators, dispatch),
        contractGas: bindActionCreators(contractActionCreatorsGas, dispatch),
    }
  }
}

const STATUS = {
  INIT: 'init',
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
};


class ChangeIban extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: STATUS.INIT,
      iban: '',
      already_registered: props.actual?'yes':'no',
      isOwner: false,
      selected: props.actual?props.actual.id:null,
      errors: {},
      type: props.type?props.type:'electricity',
      waiting_for_signature: false,
      files: []
    };

    validate.validators.IBANvalid = (value, options) => {
      if (!value && options.allowEmpty) {
        return null;
      } else {
        if (!IBANvalid(value)) {
          return i18n.t('common:text.iban_field_invalid');
        } else {
          return null;
        }
      }
    };
  }

  handleOnChangeIBAN = (event) => {
    this.setState({iban: event.target.value});
  };

  handleOnCheckIsOwner = (event, checked) => {
    this.setState({isOwner: checked});
  };

  handleOnChangeExistingIban = (event, value) => {
    this.setState({selected: Number(value)});
  };

  handleChangeFile = (fileArray) => {
    this.setState({files: fileArray});
  }

  handleSetIban = async () => {
    const VALIDATIONS = {
      iban: {
        presence: (value, attributes) => {
          if (attributes.already_registered === 'no') {
            return {allowEmpty: false};
          } else {
            return false;
          }
        },
        IBANvalid: (value, attributes) => {
          if (attributes.already_registered === 'no' && !IBANvalid(value)) {
            return {allowEmpty: false};
          } else {
            return false;
          }
        },
      },
      isOwner: {
        presence: (value, attributes) => {
          if (attributes.already_registered === 'no') {
            return {message: i18n.t('common:text.iban_change_validation')};
          } else {
            return false;
          }
        },
        inclusion: (value, attributes) => {
          if (attributes.already_registered === 'no') {
            return {
              within: [true],
              message: i18n.t('common:text.iban_change_validation')
            };
          } else {
            return false;
          }
        },
      },
      hasFiles: {
        presence: (value, attributes) => {
          if (Settings?.features?.attachmentOnIbanChange && attributes.files.length == 0) {
            return {message: i18n.t('common:text.iban_change_has_files')};
          } else {
            return false;
          }
        }
      }
    };

    const errors = validate(this.state, VALIDATIONS);
    if (errors) {
      this.setState({errors})
    } else {
      this.setState({status: STATUS.SENDING});
      const { token, contract, type } = this.props;
      const value = {
        'already_registered': this.state.already_registered,
        'bank_id': this.state.selected,
        'iban': this.state.iban,
        'owner_is_bank_owner': this.state.isOwner,
        'files': this.state.files,
      };
      try {
        let result = null;
        if (type === 'gas') {
          result = await data_create_api_resource(
            token, `contracts/${ this.props.contractId }/iban/`, value, 2
          );
        } else {
          result = await data_create_api_resource(
            token, `contracts/${ contract.name }/iban/`, value
          );
        }

        const { waiting_for_signature } = result.data;
        this.setState({waiting_for_signature});
        if (!waiting_for_signature) {
          let bank_id = null;
          if (value.already_registered === 'no') {
            if (this.props.type === 'gas'){
              await this.props.actions.contractGas.fetchIbans();
            } else {
              await this.props.actions.contract.fetchIbans();
            }
            bank_id = result.data.result;
          } else {
            bank_id = value.bank_id;
          }
          const bank_account = this.props.availableIbans.filter(
            iban => iban.id === bank_id
          )[0];
          if(this.props.type === 'gas'){
            this.props.actions.contractGas.setIban(bank_account);
          } else {
            this.props.actions.contract.setIban(bank_account);
          }
          // If no digital signature we can close the dialog
          this.handleClose();
        }
        this.setState({status: STATUS.SENT});
      } catch (e) {
        console.log("Error: ", e);
        this.setState({status: STATUS.ERROR});
      }
    }
  };

  handleClose = () => {
    if(this.props.type === 'gas'){
      this.props.actions.contractGas.editContract(false);
    } else {
      this.props.actions.contract.editContract(false);
    }
  };

  componentDidMount() {
    if (this.props.type === 'gas'){
      this.props.actions.contractGas.fetchIbans();
    } else {
      this.props.actions.contract.fetchIbans();
    }
  }

  renderInitForm() {
    const { errors } = this.state;
    const sending = this.state.status === STATUS.SENDING;
    const availableIbans = this.props.availableIbans;
    const ibanChoices = this.props.availableIbans.filter((bank) => bank.id !== _.get(this.props.actual, 'id', 0)).map((bank) => {
      const label = <div>{bank.printable_iban}</div>
      return <FormControlLabel key={bank.id} value={bank.id} control={<Radio />} label={label} disabled={sending}/>
    });
    return (
      <FormGroup id="change-iban-form" column>
        <FormControl disabled={sending}>
          <InputLabel shrink htmlFor="">
            {i18n.t('common:text.iban_change_to_use')}
          </InputLabel>
          <Select
            onChange={(event, index, value) => this.setState({ already_registered: event.target.value })}
            value={this.state.already_registered}
          >
            <MenuItem key="yes" value="yes">{i18n.t('common:text.iban_change_existent')}</MenuItem>
            <MenuItem key="no" value="no">{i18n.t('common:text.iban_change_new')}</MenuItem>
          </Select>
        </FormControl>
        { this.state.already_registered === 'no' &&
          <FormControl>
            <IBANField
                onChange={this.handleOnChangeIBAN}
                value={this.state.iban}
                errorText={errors?errors.iban:''}
                disabled={sending}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.isOwner}
                  onChange={this.handleOnCheckIsOwner}
                />
              }
              label={i18n.t('common:text.iban_change_confirmation') }
            />
            {errors && errors.isOwner &&
              <p style={{color: red[500]}}>{errors.isOwner}</p>
            }
          </FormControl>
        }
        { this.state.already_registered === 'yes' &&
          <>
            { !this.props.loadingIbans ?
              !_.isEmpty(ibanChoices) ?
                <RadioGroup
                  defaultValue={this.props.actual ? this.props.actual.id : null}
                  onChange={this.handleOnChangeExistingIban}
                  name="selectIban"
                  value={this.state.selected}
                >
                  {ibanChoices}
                </RadioGroup>
              :
                <p style={{padding: '1vh'}}>
                  { i18n.t('common:text.iban_change_none') }
                </p>
            :
              <LoadingAnimation/>
            }
          </>
          }
          { Settings?.features?.showAddAttachmentsOnIbanChange &&
            <>
              <MultiUploadButton
                onChange={this.handleChangeFile}
                label={i18n.t('common:text.multiupload_change_iban_label')}
                hint={i18n.t('common:text.multiupload_hint')}
                anotherLabel={i18n.t('common:text.multiupload_change_iban_add_file')}
                removeLabel={i18n.t('common:text.multiupload_remove_file')}
                accept={'image/png, image/jpeg, image/*,.pdf'}
              />
              {errors?.hasFiles &&
                <p style={{color: red[500]}}>{errors.hasFiles}</p>
              }
            </>
          }
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{marginTop: '16px'}}
          >
            <Button
              style={{ marginRight: '12px' }}
              variant={'contained'}
              onClick={this.handleClose}
              disabled={sending}>
              {i18n.t('common:text.power_modification_close')}
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={this.handleSetIban}
              className="submit-button"
              disabled={
                sending ||
                this.props.loadingIbans ||
                (this.state.already_registered === "yes" && _.isEmpty(ibanChoices))
              }
            >
              {sending ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.iban_change_update')}
            </Button>
          </Grid>
      </FormGroup>
    );
  }

  renderSent() {
    const { status, waiting_for_signature } = this.state;
    return (
      <div>
        { waiting_for_signature && <SignatureProcess /> }
        { status === STATUS.ERROR && <ErrorMessage/> }
        <Button
          style={{ marginTop: '12px' }}
          color={'primary'}
          variant={'contained'}
          onClick={this.handleClose}
        >
          {i18n.t('common:text.iban_change_close')}
        </Button>
      </div>
    );
  }

  render() {
    const { status } = this.state;
    const availableIbans = this.props.availableIbans.filter((bank) => bank.id !== _.get(this.props.actual, 'id', 0))
    return (
      <Grid
        container
        direction="row"
        alignItems="stretch"
      >
        <Grid item xs={6} style={{padding: 12}}>
          <Typography variant="h5">{ i18n.t('common:text.iban_change_title') }</Typography>
          { (status === STATUS.INIT || status === STATUS.SENDING) && this.renderInitForm() }
          { (status === STATUS.SENT || status === STATUS.ERROR) && this.renderSent() }
        </Grid>
        <Grid item xs={6} style={{padding: 12}}>
          <Banner code='canvi_iban_ov'/>
        </Grid>
      </Grid>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeIban);
