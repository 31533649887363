import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Grid, Paper, Typography, Box, styled } from '@mui/material';
import { Button } from '@gisce/oficina-virtual-components';
import Settings, { features } from "../../settings";
import { contraction_url } from '../../constants/i18n';
import ContractsIcon from "../../overrides/pages/AltHomeView/ContractsIcon";
import InvestmentsIcon from "../../overrides/pages/AltHomeView/InvestmentsIcon";
import InvoicesIcon from "../../overrides/pages/AltHomeView/InvoicesIcon";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const style = {
  main: {
    marginTop: "50px",
  },
  gridItem: {
    width: "350px",
  },
};

const AltHomeView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
      sx={style.main}
    >
      <Grid item sx={style.gridItem}>
        <Paper>
          <Accordion defaultExpanded={true}>
            <AccordionSummary classes={{ content: 'custom-summary-with-logo' }}>
              <ContractsIcon />
              <Box mt={3} mb={2}>
                <Typography variant="h5" noWrap>
                  {t('common:text.altHome_contract_title')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{display: "flex", flexDirection: "column"}}>
              <Button
                color="primary"
                onClick={() => navigate(contraction_url)}
              >
                {t('common:text.altHome_contract_create')}
              </Button>
              <Button
                color="primary"
                onClick={() => navigate(t('common:url.contracts'))}
              >
                {t('common:text.altHome_contract_view')}
              </Button>
              <Button
                color="primary"
                onClick={() => navigate(t('common:url.contracts_detail'))}
              >
                {t('common:text.altHome_contract_download')}
              </Button>
            {features.powerChange &&
                <Button
                  color="primary"
                  onClick={() => navigate(t('common:url.contracts_detail'))}
                >
                  {t('common:text.altHome_contract_power')}
                </Button>
            }
            {features.ibanChange &&
                <Button
                  color="primary"
                  onClick={() => navigate(t('common:url.contracts_detail'))}
                >
                  {t('common:text.altHome_contract_iban')}
                </Button>
            }
            {features.notificacionAddressChange &&
                <Button
                  color="primary"
                  onClick={() => navigate(t('common:url.contracts_detail'))}
                >
                  {t('common:text.altHome_contract_contact')}
                </Button>
            }
            {features.noCesionSips &&
                <Button
                  color="primary"
                  onClick={() => navigate(t('common:url.contracts_detail'))}
                >
                  {t('common:text.altHome_contract_sips')}
                </Button>
              }
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Grid>
      <Grid item style={style.gridItem}>
        <Paper>
          <Accordion defaultExpanded={true}>
            <AccordionSummary classes={{ content: 'custom-summary-with-logo' }}>
              <InvoicesIcon />
              <Box mt={3} mb={2}>
                <Typography variant="h5" noWrap>
                  {t('common:text.altHome_invoices_title')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{display: "flex", flexDirection: "column"}}>
              <Button
                color="primary"
                onClick={() => navigate(t('common:url.invoices'))}
              >
                {t('common:text.altHome_invoices_view')}
              </Button>
              <Button
                color="primary"
                onClick={() => navigate(t('common:url.invoices'))}
              >
                {t('common:text.altHome_invoices_download')}
              </Button>
              {Settings?.invoices?.enablePayment &&
                <Button
                  color="primary"
                  onClick={() => navigate(t('common:url.invoices'))}
                >
                  {t('common:text.altHome_invoices_payonline')}
                </Button>
              }
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Grid>
      <Grid item style={style.gridItem}>
        <Paper>
          <Accordion defaultExpanded={true}>
            <AccordionSummary classes={{ content: 'custom-summary-with-logo' }}>
              <InvestmentsIcon />
              <Box mt={3} mb={2}>
                <Typography variant="h5" noWrap>
                  {t('common:text.altHome_consumption_title')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{display: "flex", flexDirection: "column"}}>
              <Button
                color="primary"
                onClick={() => navigate(t('common:url.contracts_detail'))}
              >
                {t('common:text.altHome_consumption_view')}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AltHomeView;