import { useEffect, useState } from "react";
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { FilterCreator, useFilter } from "./FilterCreator";
import Settings from "../../settings";
import { useTranslation } from "react-i18next";

const Filter = ({ categories, applySearchFilter, initialItemsPage, initialFilters, disableApply}) => {
  const { t } = useTranslation();

  const [filterList, setFilterList] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [canFilter, setCanFilter] = useState(false);
  const [innerItemsPage, setInnerItemsPage] = useState(initialItemsPage);

  const { createFilter } = useFilter();

  const onDelete = () => {
    setCreating(false);
  };

  const onCreate = (filter) =>  {
    setFilterList([...filterList, filter]);
    setCanFilter(true);
  }

  const handleDelete = (deleteIdx) => {
    setFilterList(filterList.filter((f,idx) => idx !== deleteIdx));
    setCanFilter(true);
  };

  const handleDeleteAll = () => {
    setFilterList([]);
    if (filtersApplied) {
      applySearchFilter(getSearchFilterList(filterList), innerItemsPage);
      setFiltersApplied(false);
    }
    setCanFilter(false);
  }

  const handleItemsPage = (event) => {
    if(event.target.value !== innerItemsPage) {
      setInnerItemsPage(event.target.value);
      setCanFilter(true);
    }
  }

  const handleChangeState = (toggleIdx) => {
    setFilterList(
      filterList.map((f,idx) => {
        if (idx === toggleIdx) {
          return {...f, active: !f.active};
        } else {
          return f;
        }
      })
    );
    setCanFilter(true);
  }

  const getSearchFilterList = (filters) => {
    let searchFilterList = '';
    filters.map((f, idx) => {
      if (f.active) {
        searchFilterList += f.search + (idx < filters.length - 1 ? ", " : "");
      }
    })
    return searchFilterList;
  };

  const applyFilters = () => {
    if (canFilter) {
      applySearchFilter(getSearchFilterList(filterList), innerItemsPage);
      setFiltersApplied(true);
      setCanFilter(false);
    }
  };

  useEffect(() => {
    if(initialFilters?.length) {
      let newFilterList = [];
      initialFilters.map(filter => {
        const newFilter = createFilter(filterList.length, filter.value, filter.category, filter.type);
        newFilterList = [...newFilterList, newFilter];
      });
      setFilterList(newFilterList);
    } else {
      setFilterList([])
    }
  }, [initialFilters]);

  useEffect(() => {
    setInnerItemsPage(initialItemsPage);
  }, [initialItemsPage]);

  return (
    <div>
      <Accordion elevation={0} style={{borderRadius: "10px", marginBottom: "10px", border: "1px solid #d9d9d9"}}> 
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
          <Typography variant="h6">{t("common:text.filter_title")}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box width="100%">

            <FilterCreator categories={categories} onDelete={onDelete} onCreate={onCreate}/>

            <>
              {filterList.length > 0 &&  <Typography variant="body2" style={{marginTop: "10px", marginBottom: "10px", color: "grey"}}>
                  {t("common:text.filter_applied")}
                </Typography>
              }

              {filterList.length > 0 && 
                <Grid container spacing={1}>
                  { filterList
                    .map((filter, idx) => <Grid item key={idx}>
                      <Chip 
                        color={filter.active ? "primary" : "default"}
                        clickable
                        onClick={() => handleChangeState(idx)}
                        label={filter.render} 
                        onDelete={() => handleDelete(idx)}
                      />
                    </Grid>)
                  }
                </Grid>
              }

                <Divider style={{marginBottom: "10px", marginTop: "10px"}}/>
                
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item container xs={12} sm={6} spacing={2} alignItems="center">
                    <Grid item>
                      {t("common:text.filter_n_items")}
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <Select
                          value={innerItemsPage}
                          onChange={handleItemsPage}
                          type="number"
                          id='items-per-page'
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={12}>12</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={150}>150</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} sm={6} justifyContent="flex-end" spacing={1}>

                    <Grid item>
                      <Button 
                        fullWidth
                        style={{borderRadius: "10px"}}
                        variant="contained"
                        startIcon={<DeleteSweepIcon />} 
                        disabled={filterList.length === 0 || (filterList.length !== 0 && disableApply)}
                        onClick={() => handleDeleteAll()}
                      >
                        {t("common:text.filter_remove")}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button 
                        fullWidth
                        style={{borderRadius: "10px"}}
                        color="primary"
                        variant="contained"
                        startIcon={<SearchIcon />} 
                        disabled={disableApply}
                        onClick={() => applyFilters()}
                      >
                        {t("common:text.filter_apply")}
                      </Button>
                    </Grid>

                  </Grid>

                </Grid>

              </>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Filter;