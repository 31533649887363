import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import _ from "lodash";
import { LoadingAnimation } from "../LoadingAnimation";
import { cupsValid } from "../form-fields/CUPSField";
import { EnergyConsumptionField } from "../EnergyConsumption";
import TextFieldReadOnly from "../TextFieldReadOnly";
import PowerField from "../PowerField";
import EurosField from "../EurosField";
import LeadContact from "../LeadContact";
import {
	data_fetch_api_resource,
	data_create_api_resource
} from "../../utils/http_functions";
import Settings from "../../settings";
import { i18n, dayjs } from "../../config";
import Result from "./Result";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/price-comparator";

import { Button, MenuItem, Select, FormControl, Grid, FormHelperText, Divider,
  InputLabel, Paper, Radio, RadioGroup, FormControlLabel, Box, TextField } from "@mui/material";
import { red } from "@mui/material/colors";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// NOTE: This component is not ready for production!!
// TODO: Fix schema validation (enums) and add comparatorMaxConsumption validation
// FOLLOW: https://mui.com/material-ui/react-select/
// FOLLOW: https://timjames.dev/blog/validating-dependent-fields-with-zod-and-react-hook-form-2fa9

//Això ho deixo duplicat perquè crec que a MUI5 canvia el funcionament
const langDateFormat = { 'es-ES': "DD/MM/YYYY", 'ca-ES': "DD/MM/YYYY", 'en-EN': "MM/DD/YYYY" }

const PriceComparator = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [errorText, setErrorText] = useState("");
	const [invoiceSent, setInvoiceSent] = useState(false);
	const [mode, setMode] = useState("auto");
	const [availableComers, setAvailableComers] = useState([]);
	const [availableAccessTariffs, setAvailableAccessTariffs] = useState([]);
	const [saving, setSaving] = useState(null);

	// Zod validation schema
	const schema = z.object({
		cups: z.string()
			.min(1, t('common:text.required_field'))
			.refine(
				cups => cupsValid(cups),
				{message: t('common:text.contractation_cups_not_valid')}
			),
		comerOrigin: z.enum(availableComers),
		access_tariff: z.enum(availableAccessTariffs),
		p1: z.number({
      required_error: t('common:text.required_field'),
    })
    .positive({
      message: t('common:text.power_field_not_valid'),
    }),
		p2: z.number().positive({
      message: t('common:text.power_field_not_valid'),
    })
		.optional(),
		p3: z.number().positive({
      message: t('common:text.power_field_not_valid'),
    }).optional(),
		startDate: z.date({
			errorMap: (issue) => ({
				message: issue.code === "invalid_date" ? t('common:text.date_not_valid') : t('common:text.required_field'),
			}),
		})
		.refine(
			date =>  date.getTime() <= Date.now(),
			{message: t('common:text.date_not_valid')}
		),
		endDate: z.date({
			errorMap: (issue) => ({
				message: issue.code === "invalid_date" ? t('common:text.date_not_valid') : t('common:text.required_field'),
			}),
		})
		.refine(
			date => date.getTime() <= Date.now(),
			{message: t('common:text.date_not_valid')}
		),
		power: z.number({
      required_error: t('common:text.required_field'),
    })
    .positive({
      message: t('common:text.power_field_not_valid'),
    }),
		power2: z.number()
    .positive({
      message: t('common:text.power_field_not_valid'),
    })
		.optional(),
		invoiceAmount: z.number({
      required_error: t('common:text.required_field'),
    })
    .positive({
      message: t('common:text.power_field_not_valid'),
    }),
	});

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		register,
		formState: { errors }
	} = useForm({
		resolver: zodResolver(schema),
		defaultValues: {
			cups: "",
			comerOrigin: {},
			access_tariff: {},
			p1: undefined,
			p2: undefined,
			p3: undefined,
			startDate: null,
			endDate: null,
			power: undefined,
			power2: undefined,
			invoiceAmount: undefined,
		}
	});

	console.log("errors???", errors);

	const cups = watch("cups");
	const power = watch("power");

	const accessTariff = watch('access_tariff');
	console.log("access_tariff", accessTariff);
	const selectedTariff = availableAccessTariffs.find(t => t.id === accessTariff);

	useEffect(() => {
		fetchComers();
		if (Cookies.marketingConsentGiven()) {
			marketingHook();
		}
	}, []);

	useEffect(() => {
		if (cups.length === 20 || cups.length === 22) {
			fetchDataFromCUPS(cups);
		}
	}, [cups]);

	useEffect(() => {
		if (power) {
			fetchAvailableAccessTariffs(power);
		}
	}, [power]);

	const fetchComers = async () => {
		const result = await data_fetch_api_resource(null, "leads/comers");
		if (result && result.status === 200 && result.data) {
			setAvailableComers(result.data);
		}
	};

	const fetchDataFromCUPS = async (cups) => {
		const result = await data_fetch_api_resource(
			null,
			`contractacio/config/cups/${cups}?old=0`
		);
		if (result && result.status === 200) {
			setValue("power", result.data?.power ?? "");
			// setValue("power2", result.data?.power_p2 ?? "");
			setValue(
				"access_tariff", 
				result.data?.access_tariff?.id
			);
		}
	};

	const fetchAvailableAccessTariffs = async (power) => {
		const result = await data_fetch_api_resource(null, `tariff?power=${power}&old=0`);
		if (result && result.status === 200 && result.data) {
			if (accessTariff && result.data.findIndex( elm => elm.id === accessTariff) === -1 ) {
				console.log(availableAccessTariffs, accessTariff);
				setValue("access_tariff", "");
			}
			setAvailableAccessTariffs(result.data);
		}
	};

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const result = await data_create_api_resource(null, "price-compare/", {
				mode,
				...data,
				startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
				endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
			});
			if (result && result.data) {
				setSaving(result.data);
			}
		} catch (error) {
			setErrorText(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleUpload = async (data) => {
		setLoading(true);
		try {
			await data_create_api_resource(null, "price-compare/", {
				mode,
				...data,
			});
			setInvoiceSent(true);
		} catch (error) {
			console.log("Invoice sending failed.");
			setErrorText(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleGoBack = () => {
		setSaving(null);
	};

	const handleHelpPanelClick = () => {
		if (i18n.exists('common:url.calculatorHelp')) {
			navigate(t('common:url.calculatorHelp'));
		}
	};

	const renderHeader = () => {
    let modeSelectorParagraph = <p>{i18n.t('common:text.price_comparator_intro')}</p>
    let modeSelector = (
      <RadioGroup
        name="mode"
        defaultValue={"auto"}
        value={this.state.mode}
        onChange={this.handleChangeMode}
      >
        <FormControlLabel value="auto" label={i18n.t('common:text.price_comparator_option_manual')} control={<Radio/>}/>
        <FormControlLabel
          value="manual"
          label={i18n.t('common:text.price_comparator_option_email')}
          control={<Radio/>}
        />
      </RadioGroup>
    );

		// INFO: By default will show the readio button `writed_data_or_attached_data`!
		if (Settings?.comparator?.calculationMode === "writed_data") {
      modeSelector = undefined;
      modeSelectorParagraph = <p>{i18n.t('common:text.price_comparator_for_writed_data_intro')}</p>
    }

		return <Box className="header" sx={{ display: "flex" }}>
			<Box className="image" />
			<Box sx={{ flex: 1 }}>
				<h1 style={{ marginTop: "0px" }}>
					{t('common:text.price_comparator_title', 
						{ companyName: Settings?.organization?.name })}
				</h1>
				{ modeSelectorParagraph }
				{!saving && modeSelector}
			</Box>
		</Box>
	};

	const renderAutoForm = () => (
		<div className="auto-form">
			<Paper elevation={1} style={{ padding: "2.5rem" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<TextField
								{...register("cups")}
								label={"CUPS"}
								style={{ width: "100%" }}
								error={!!errors.cups}
							/>
							{errors.cups?.message && 
								<FormHelperText error>
									{errors.cups.message}
								</FormHelperText>
							}
							<div className="helper-text">{t('common:text.contractation_cups_helper')}</div>

							<Controller
								name="power"
								control={control}
								render={({ field }) => (
									<PowerField
										label={t('common:text.price_comparator_power') + "*"}
										style={{ width: "100%" }}
										decimalScale={3}
										error={!!errors.power}
										field={field}
									/>
								)}
							/>
							{errors.power?.message && 
								<FormHelperText error>
									{errors.power.message}
								</FormHelperText>
							}
						</Grid>

						<Grid item xs={12} md={6} lg={4}>
							<Controller
								name="comerOrigin"
								control={control}
								render={({ field }) => (
									<FormControl style={{ width: "100%" }}>
										<InputLabel shrink>
											{t('common:text.price_comparator_company') + "*"}
										</InputLabel>
										<Select
											{...field}
											error={!!errors.comerOrigin}
											className="select-field"
										>
											<MenuItem disabled value="">
												<em>None</em>
											</MenuItem>
											{availableComers.map(c => (
												<MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
							{errors.comerOrigin?.message && 
								<FormHelperText error>
									{errors.comerOrigin.message}
								</FormHelperText>
							}

						{selectedTariff && /2.0TD/gm.test(selectedTariff.name) &&
							<>
								<Controller
									name="power2"
									control={control}
									render={({ field }) => (
										<PowerField
											label={t('common:text.price_comparator_power2')}
											style={{ width: "100%" }}
											decimalScale={3}
											error={!!errors.power2}
											field={field}
										/>
									)}
								/>
								{errors.power2?.message && 
									<FormHelperText error>
										{errors.power2.message}
									</FormHelperText>
								}
							</>
						}

							<Controller
								name="access_tariff"
								control={control}
								render={({ field }) => (
									<FormControl style={{ width: "100%", marginTop: 20 }}>
										<InputLabel shrink>
											{t('common:text.price_comparator_access_tariff') + "*"}
										</InputLabel>
										<Select
											{...field}
											error={!!errors.access_tariff}
											className="select-field"
										>
											<MenuItem disabled value="">
												<em>None</em>
											</MenuItem>
											{availableAccessTariffs.map(c => (
												<MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							/>
							{errors.access_tariff?.message && 
								<FormHelperText error>
									{errors.access_tariff.message}
								</FormHelperText>
							}
						</Grid>

						<Grid item xs={12} md={6} lg={4}>
							<div style={{ maxWidth: 300 }}>
								<label className="field-label">
									{t('common:text.price_comparator_consumption')}
								</label>
							</div>

							<Controller
								name="p1"
								control={control}
								render={({ field }) => (
									<EnergyConsumptionField
										field={field}
										label="P1"
										className="energy-consumption-field"
										decimalScale={0}
										error={!!errors.p1}
									/>
								)}
							/>
							{errors.p1?.message && 
								<FormHelperText error>
									{errors.p1.message}
								</FormHelperText>
							}

							{selectedTariff && /dh|DH|3\.0A|2.0TD/gm.test(selectedTariff.name) && (
								<>
									<Controller
										name="p2"
										control={control}
										render={({ field }) => (
											<EnergyConsumptionField
												field={field}
												label="P2"
												className="energy-consumption-field"
												decimalScale={0}
												error={!!errors.p2}
											/>
										)}
									/>
									{errors.p2?.message && 
										<FormHelperText error>
											{errors.p2.message}
										</FormHelperText>
									}
								</>
							)}
							
							{selectedTariff && /dhs|DHS|3\.0A|2.0TD/gm.test(selectedTariff.name) && (
								<>
									<Controller
										name="p3"
										control={control}
										render={({ field }) => (
											<EnergyConsumptionField
												field={field}
												label="P3"
												className="energy-consumption-field"
												decimalScale={0}
												error={!!errors.p3}
											/>
										)}
									/>
									{errors.p3?.message && 
										<FormHelperText error>
											{errors.p3.message}
										</FormHelperText>
									}
								</>
							)}
						</Grid>
					</Grid>

					<Divider style={{ marginTop: 20 }} />
					<TextFieldReadOnly label={t('common:text.price_comparator_invoicing_period')} />

					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={4}>
								<Controller
									name="startDate"
									control={control}
									render={({ field }) => (
										<DatePicker
											{...field}
											onChange={(val) => field.onChange(val.toDate())}
											label={t('common:text.date_interval_start') + "*"}
											renderInput={(params) => <TextField {...params} error={!!errors.startDate} />}
											slotProps={{												
												textField: {
													error: !!errors.startDate
												}
											}}
										/>
									)}
								/>
								{errors.startDate?.message && 
									<FormHelperText error>
										{errors.startDate.message}
									</FormHelperText>
								}
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Controller
									name="endDate"
									control={control}
									render={({ field }) => (
										<DatePicker
											{...field}
											onChange={(val) => field.onChange(val.toDate())}
											label={t('common:text.date_interval_end') + "*"}
											renderInput={(params) => <TextField {...params} error={!!errors.endDate} />}
											slotProps={{												
												textField: {
													error: !!errors.endDate
												}
											}}
										/>
									)}
								/>
								{errors.endDate?.message && 
									<FormHelperText error>
										{errors.endDate.message}
									</FormHelperText>
								}
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Controller
									name="invoiceAmount"
									control={control}
									render={({ field }) => (
										<EurosField
											field={field}
											label={t('common:text.price_comparator_invoice_amount') + "*"}
											fullWidth={true}
											error={!!errors.invoiceAmount}
										/>
									)}
								/>
								{errors.invoiceAmount && <FormHelperText error>{errors.invoiceAmount.message}</FormHelperText>}
							</Grid>
						</Grid>
					</LocalizationProvider>

					<Button
						type="submit"
						color={'primary'}
						variant={'contained'}
						className="primary-btn submit-btn"
					>
						{t('common:text.price_comparator_submit')}
					</Button>
				</form>
			</Paper>
			{_.get(Settings, "comparator.sideContact") && (
				<HelpPanel onClick={handleHelpPanelClick} />
			)}
		</div>
	);

	const renderManualForm = () => (
		<Paper elevation={1} style={{ padding: "2.5rem" }}>
			<div className="contact-container">
				<h2>{t('common:text.price_comparator_contact_subtitle')}</h2>
				<LeadContact onSubmit={handleUpload} attachInvoice={true} />
			</div>
		</Paper>
	);

	return (
		<div className="price-comparator-electricity">
			{loading && <LoadingAnimation />}
			{invoiceSent ? (
				<h3>{t('common:text.price_comparator_contact_sent')}</h3>
			) : !loading && !saving ? (
				<div>
					{renderHeader()}
					{mode === "auto" && renderAutoForm()}
					{mode === "manual" && renderManualForm()}
				</div>
			) : (
				!loading && <Result cups={cups} saving={saving} onGoBack={handleGoBack} />
			)}
			<div style={{ color: "#f00" }}>{errorText}</div>
		</div>
	);
};

export default PriceComparator;
