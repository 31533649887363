import { 
	ClientIDLoginParam, 
	LoginRequestResponse 
} from '@/types/models/hooks/auth';
import { 
	LOGIN_CLIENTID_FAILURE, 
	LOGIN_CLIENTID_REQUEST, 
	LOGIN_CLIENTID_SUCCESS 
} from '../../constants';
import { 
	define_token, 
	get_token_by_client_id, // TODO: Moure això a un service
	undefine_token 
} from '@/utils/http_functions';
import { Dispatch } from 'redux';

export function loginClientIdSuccess(token: string) {
	define_token(token);

	return {
		type: LOGIN_CLIENTID_SUCCESS,
		payload: {
			token,
			redirect: "common:url.newElectricityContract"
		},
	};
}

export function loginClientIdFailure({
  statusCode=403, 
	statusText='', 
	statusType="error"
}) {
	undefine_token();
	return {
		type: LOGIN_CLIENTID_FAILURE,
		payload: {
			statusCode,
			statusText,
			statusType
		},
	};
}

export function loginClientIdRequest() {
	undefine_token();

	return {
		type: LOGIN_CLIENTID_REQUEST,
	};
}

export function loginClientId({
	clientId,
	secret
}: ClientIDLoginParam) {
	return (dispatch: Dispatch) => {
		dispatch(loginClientIdRequest());
		get_token_by_client_id(clientId, secret)
		.then(({data}) => {
			try {
				if (data.token) {
					dispatch(loginClientIdSuccess(data.token));
				} else {
					console.log("error");
					dispatch(loginClientIdFailure({
						statusCode: 401,
						statusText: "common:text.login_view_clientid_invalid",
						statusType: "error"
					}));
				}
			} catch (e) {
				dispatch(loginClientIdFailure({}));
			}
		})
		.catch(error => {
			dispatch(loginClientIdFailure(error));
		});
	};
}