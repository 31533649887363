import _ from "lodash";
import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { red } from '@mui/material/colors';

import { SelectCardGroup, SelectCard } from "@gisce/oficina-virtual-components";

import * as actionCreators from "../../actions/newElectricityContract";
import { i18n } from "../../config";
import { LoadingAnimation } from '../LoadingAnimation';
import RichContent from "../RichContent";
import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class ElectricityProductSelect extends React.Component {

  fetchAvailableProducts = async () => {
    const { power, power2, power3, power4, power5, power6, accessTariff, selfcons, cups, promotionalCode } = this.props;
    const max_power = Math.max(power, power2, power3, power4, power5, power6);
    if (max_power) {
      this.props.fetchAvailableProducts(max_power, accessTariff, selfcons, cups, promotionalCode);
    }
  };

  componentDidMount() {
    this.fetchAvailableProducts();
  }

  componentDidUpdate(prevProps) {
    if ( this.props.accessTariff !== null) {
      if (this.props.power !== prevProps.power || this.props.power2 !== prevProps.power2 || this.props.accessTariff !== prevProps.accessTariff) {
        this.fetchAvailableProducts();
      }
    }

    if (this.props.promotionalCode !== prevProps.promotionalCode) {
      this.fetchAvailableProducts();
    }
  }

  handleChange = (value) => {
    const selected = this.props.availableProducts.filter((t) => t.id === Number(value));
    const product = selected[0];
    this.props.selectProduct(product);
    if (typeof this.props.onChange === "function") {
      this.props.onChange(product);
    }
  };

  render() {
    const { selectedProduct } = this.props;
    return this.props.isFetching ? (
      <LoadingAnimation />
    ) : (
      <div>
        <SelectCardGroup container spacing={3} onChange={this.handleChange} value={selectedProduct?.id}>
          {this.props.availableProducts.map(
            product => <SelectCard
              xs={12}
              md={6}
              key={product.id}
              value={product.id}
              name={product.name}
              description={product.ov_description}
            />
          )}
        </SelectCardGroup>
        {!!this.props.power &&
          !!this.props.accessTariff &&
          _.isEmpty(this.props.availableProducts) && (
            <div style={{ color: red[500] }}>
              <RichContent
                content={i18n.t('common:text.contractation_no_available_tariffs',
                  {
                    phone: _.get(Settings, "organization.phone_crm", ""),
                    phoneLink: _.get(Settings, "organization.phone_crm", "").replace(/\s/g, '')
                  }
                )}
              />
            </div>
          )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ElectricityProductSelect);

ElectricityProductSelect.propTypes = {
  power: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selfConsumption: PropTypes.bool,
  cups: PropTypes.string,
  availableProducts: PropTypes.array,
  selectedProduct: PropTypes.object,
  isFetching: PropTypes.bool,
};
