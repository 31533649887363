import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import { executeHooks } from "../../utils/hooks";
import { useEffect } from "react";

const RichContent = ({ html, content, ...rest }) => {

  useEffect(() => {
    if (html && content) {
      executeHooks(content);
    }
  }, []);

  console.log("rest", rest)

  if (html) {
    return <ReactMarkdown rehypePlugins={[rehypeRaw]} children={html} />
  } else {
    return <ReactMarkdown {...rest} style={{padding: 0}}>{content}</ReactMarkdown>;
  }

}

export default RichContent;