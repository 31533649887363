import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import AreaField from "./fields/areaField";
import PeopleField from "./fields/peopleField";
import TextFieldReadOnly from "../TextFieldReadOnly";
import Appliance from "./Appliance";
import {APPLIANCES, ownIcons} from "./custom/config";
import {
  data_create_api_resource
} from "../../utils/http_functions";
import LeadContact from "../LeadContact";
import Settings from "../../settings";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/power-calculator";

import { 
  RadioGroup, FormControlLabel, Radio, 
  Box, Typography, Grid 
} from '@mui/material';
import {
  Button
} from '@gisce/oficina-virtual-components'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const CalculadoraPotencia = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [appliances, setAppliances] = useState([]);
  const [cofSimul, setCofSimul] = useState(0.4);
  const [showResult, setShowResult] = useState(false);
  const [leadSent, setLeadSent] = useState(false);
  const [data, setData] = useState({})

  const addAppliance = (appliance) => {
    const newAppliances = [...appliances.filter(
      v => v.name !== appliance.name
    ), appliance];
    setAppliances(newAppliances);
  }

  const removeAppliance = (appliance) => {
    const newAppliances = appliances.filter(
      v => v.name !== appliance.name
    );
    setAppliances(newAppliances);
  }

  const handleChangeAppliance = (appliance) => {
    if (appliance.enabled) {
      addAppliance(appliance);
    } else {
      removeAppliance(appliance);
    }
  }

  const handleSubmit = (values) => {
    setData(values);
    sendLead(values);
    setShowResult(true);
  }

  const leadSubmit = async (personalData) => {
    const newData = {...data, ...personalData};
    setData(newData);
    const response = await sendLead(newData);
    if (response.status === 200) {
      setLeadSent(true);
    }
  }

  const sendLead = async (values) => {
    values.optimalPower = Math.round(appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100;
    const response = await data_create_api_resource(
      null,
      'leads/potencia',
      {...values, appliances, cofSimul}
    );
    return response;
  }

  const handleHelpPanelClick = () => {
    if(i18n.exists('common:url.calculatorHelp')){
      navigate(t('common:url.calculatorHelp'));
    }
  };

  useEffect(() => {
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }, []);

  const maxPower = Math.round(appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100;

  return (
    <div className="power-calculator">
      {!leadSent && <>
        {!showResult && 
          <Form
            onSubmit={(values) => handleSubmit(values)}
            validate={(values) => {
              const errors = {};

              // CUPS
              if (!values.cups) {
                errors.cups = t('common:text.lead_power_cups_validation_required');
              } else if (!cupsValid(values.cups)) {
                errors.cups = t('common:text.contractation_cups_not_valid');
              }

              // Area
              if (!values.area) {
                errors.area = t('common:text.lead_area_validation_required');
              }

              // Persones
              if (!values.numPersones) {
                errors.numPersones = t('common:text.lead_people_validation_required');
              }

              return Object.keys(errors).length
                ? errors
                : true

            }}
            render={({
              handleSubmit,
              pristine,
              submitting,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography variant="h5">
                    {t('common:text.lead_power_title')}
                  </Typography>
                </Box>

                <Grid container spacing={3} justifycontent={'center'}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Field
                      label="CUPS*"
                      name="cups"
                      component={CUPSField}
                      style={{ width: 300 }}
                    />
                    <div className="helper-text" style={{ width: 300 }}>
                      {t('common:text.contractation_cups_helper')}
                    </div>
                    <Field
                      name="area"
                      label={t('common:text.lead_power_area')}
                      component={AreaField}
                    />
                    <Field
                      label={t('common:text.lead_power_people')}
                      name="numPersones"
                      component={PeopleField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {/* TODO: Moure aquest radio button a dins el form */}
                    <TextFieldReadOnly label={t('common:text.lead_power_cofsimul')}>
                      <RadioGroup
                        name="shipSpeed"
                        defaultValue={0.4}
                        onChange={(event) =>
                          setCofSimul(Number(event.target.value))
                        }
                        value={cofSimul}
                      >
                        <FormControlLabel
                          value={0.25}
                          control={<Radio />}
                          label={t('common:text.lead_power_cofsimul_pocs')}
                        />
                        <FormControlLabel
                          value={0.4}
                          control={<Radio />}
                          label={t('common:text.lead_power_cofsimul_alguns')}
                        />
                        <FormControlLabel
                          value={0.6}
                          control={<Radio />}
                          label={t('common:text.lead_power_cofsimul_molts')}
                        />
                      </RadioGroup>
                    </TextFieldReadOnly>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    {Settings?.powerCalculator?.sideContact && 
                      <HelpPanel onClick={handleHelpPanelClick} />
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="h5">
                        {t('common:text.lead_power_appliances')}
                      </Typography>
                    </Box>
                    <TextFieldReadOnly label={t('common:text.lead_power_appliances_question')}>
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: 12,
                          color: "rgba(0, 0, 0, 0.3)",
                          marginLeft: 10,
                        }}
                      >
                        {t('common:text.lead_power_appliances_question_hint')}
                      </div>
                    </TextFieldReadOnly>
                    <div className="gridList">
                      {APPLIANCES.map((appliance, index) => (
                        <Appliance
                          key={index}
                          {...appliance}
                          cofSimul={cofSimul}
                          area={values.area}
                          onChange={handleChangeAppliance}
                        />
                      ))}
                    </div>
                    <Button
                      type="submit"
                      disabled={submitting || pristine}
                      variant={"contained"}
                    >
                      {t('common:text.lead_power_calculate')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        }
        {showResult && 
          <div className="result">
            {/* {!helpRequested && ( */}
              <div>
                <Typography variant="h4" align="center">
                  {t('common:text.lead_power_optimal')}
                </Typography>
                <div className="value">
                  {maxPower.toString().replace(".", ",")} kW
                </div>
                <div>
                  <Typography variant="body2" align="center">
                    {t('common:text.lead_power_text1')}
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body2">
                        {t('common:text.lead_power_text2')}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {t('common:text.lead_power_text3')}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {t('common:text.lead_power_text4')}
                      </Typography>
                    </li>
                  </ul>
                  <p>
                    {t('common:text.lead_power_essential')}
                  </p>
                  <p>
                    {t('common:text.lead_power_aclariment_canvi_potencia')}
                  </p>
                  <p>
                    {t('common:text.lead_power_aclariment', 
                      { companyName: Settings.organization.name }
                    )}
                  </p>
                </div>
              </div>
              <LeadContact
                changePowerHelp={true}
                leadSubmit={(personalData) => leadSubmit(personalData)}
              />
          </div>
        }
      </>}
      {leadSent &&
        <Box>
          <Typography variant="h4">
            {t('common:text.lead_thank_you')}
          </Typography>
        </Box>
      }
      {!ownIcons && (
        <footer style={{ marginTop: 100 }}>
          <p>
            Icones creats per
            <a href="https://www.flaticon.com/authors/pause08">pause08</a>,
            <a href="https://www.flaticon.com/authors/smashicons">
              Smashicons
            </a>
            ,<a href="https://www.flaticon.com/authors/monkik">monkik</a>,
            <a href="https://www.flaticon.com/authors/freepik">Freepik</a>,
            <a href="https://www.flaticon.com/authors/photo3idea-studio">
              photo3idea_studio
            </a>
            ,
            <a href="https://www.flaticon.com/authors/ultimatearm">
              ultimatearm
            </a>
            ,<a href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>,
            de <a href="http://www.flaticon.com">www.flaticon.com</a>
          </p>
        </footer>
      )}
    </div>
  );
}


export default CalculadoraPotencia;
