import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newGasContract";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import { useTranslation } from 'react-i18next';
import { data_fetch_api_resource } from "../../utils/http_functions";
import AddressField, {
  validarReferenciaCatastral,
} from "../form-fields/AddressField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import CheckboxField from "../form-fields/CheckboxField";
import CNAEField from "../form-fields/CNAEField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import RichContent from "../RichContent";
import FlexRow from "../FlexRow";
import { CircularProgress, Button, Box, Typography, Grid, Divider } from "@mui/material"
import { collectFormData } from "../NewElectricityContract/form-data";
import { sendElectricityLeads, sendGasLeads, setAboutHomeData, setLeadIndex } from "../../actions/newElectricityContract";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutHome = (props) => {
  const { t } = useTranslation();
  const [comerOriginEnabled, setComerOriginEnabled] = useState(false);
  const [cupsInvalid, setCupsInvalid] = useState(null);

  const handleSubmit = async (values) => {
    const collectedData = collectFormData(values);
    setAboutHomeData(collectedData); // call action
    if (props.shouldSaveLeadsFunc()) { // todo usa useSelector per agafar el leadIndex i el leadCo
      sendElectricityLeads(values); // call action
      if (props.gas && props.gasProps.cups) {
        // no estic segur <
        const gasFormData = collectFormData({
          ...props.electricity,
          cups: props.gasProps.cups,
          comerOrigin: props.gasProps.comerOrigin,
          accessTariff: props.gasProps.accessTariff,
          selectedProduct: props.gasProps.selectedProduct,
          invoice: props.gasProps.invoice,
          owner: {
            ...props.electricity.owner,
            changeOwner: props.gasProps.changeOwner,
          },
        });
        sendGasLeads(gasFormData); // call action
      }
    }
    setLeadIndex(props.electricity.leadIndex + 1); // call action
  };

  const validateCUPSAvailability = async (values, storeSIPSData = false) => {

    const result = await data_fetch_api_resource(
      null,
      `gas/contractacio/config/cups/${values.cups}`,
      2
    );
    let r = null;
    if (result && result.status === 200) {
      if (result.data.cups_availability) {
        if (!result.data.cups_availability.available) {
          r = result.data.cups_availability.reason;
          setCupsInvalid(result.data.cups_availability.reason);
        } else {
          setCupsInvalid(null)
          if (storeSIPSData) {
            props.storeSIPSData({
              ...result.data,
              leadsConsent: values.leadsConsent,
              cups: values.cups,
              residenceType: values.residenceType,
              cnae: values.cnae,
            });
          }
        }
      }
    } else {
      r = t('common:text.cannot_validate_async');
    }

    return (r && { cups: r }) || null;
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value && cupsValid(value)) {
      validateCUPSAvailability(values, true);
    }
  };

  const { isAuthenticated } = props;

  // Set leads consent to true by default if user is authenticated
  let leadsConsent = isAuthenticated;
  if (props.leadsConsent !== null) {
    leadsConsent = props.leadsConsent;
  }

  const leadsNote = (
    <RichContent
      content={
        t('common:text.contractation_leads_note', {
          client: Settings.organization?.name ?? "",
          privacyPolicy: Settings.links?.privacyPolicy ?? "",
        })
      }
    />
  );

  return (
    <div>
      {Settings.newGasContract?.sectionHeaders &&
        <Box>
          <Typography variant="h4">{t('common:text.contractation_about_home')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          leadsConsent: leadsConsent,
          cups: props.cups,
          address: props.address,
          residenceType: props.residenceType,
          cnae: props.cnae,
          invoice: props.invoice,
          comerOrigin: props.comerOrigin,
          activeContract: props.activeContract
        }}
        validate={(values) => {
          const errors = {};

          if (!values.cups) {
            errors.cups = t('common:text.required_field');
          } else if (!cupsValid(values.cups)) {
            errors.cups = t('common:text.contractation_cups_not_valid');
          }

          // Zipcode
          if (!values?.address?.zipcode) {
            errors.address = { ...errors.address, zipcode: t('common:text.required_field') }
          } else if (values?.address?.zipcode?.length !== 5) {
            errors.address = { ...errors.address, zipcode: t('common:text.contractation_postal_code_length') }
          }

          // City
          if (!values?.address?.city) {
            errors.address = { ...errors.address, city: t('common:text.required_field') }
          }

          // Street Type
          if (!values?.address?.tv) {
            errors.address = { ...errors.address, tv: t('common:text.required_field') }
          }

          // Street Name
          if (!values?.address?.nv) {
            errors.address = { ...errors.address, nv: t('common:text.required_field') }
          } else if (values?.address?.nv?.length >= 256) {
            errors.address = { ...errors.address, nv: t('common:text.address_validation_nv_length') }
          }

          // Street Number
          if (!values?.address?.pnp) {
            errors.address = { ...errors.address, pnp: t('common:text.required_field') }
          } else if (values?.address?.pnp?.length >= 9) {
            errors.address = { ...errors.address, pnp: t('common:text.address_validation_pnp_length') }
          }

          // Block
          if (values?.address?.bq?.length >= 4) {
            errors.address = { ...errors.address, bq: t('common:text.address_validation_bq_length')}
          }

          // Stair
          if (values?.address?.es?.length >= 4) {
            errors.address = { ...errors.address, es: t('common:text.address_validation_es_length')}
          }

          // Floor
          if (values?.address?.pt?.length >= 10) {
            errors.address = { ...errors.address, pt: t('common:text.address_validation_pt_length')}
          }

          // Door
          if (values?.address?.pu?.length >= 4) {
            errors.address = { ...errors.address, pu: t('common:text.address_validation_pu_length')}
          }

          // TODO: There's probably missings things here like the A3 component and other shit I made
          //  for the electricity contractation. Sorry.

          // Referencia Catastral
          if (validarReferenciaCatastral(values?.address?.ref_catastral) !== 1) {
            errors.address = {...errors.address, ref_catastral: t('common:text.contractation_not_valid_ref')};
          }

          // File Upload
          const fileErrors = validateFileUpload(values.invoice, {
            min: 1,
            minMessage: t('common:text.multiupload_validation_min'),
          });
          if (fileErrors) {
            errors.invoice = fileErrors;
          }

          return Object.keys(errors).length ? errors : validateCUPSAvailability(values, false);
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            {Settings.features?.leads && (
              <div className="helper-text">
                {isAuthenticated ? (
                  leadsNote
                ) : (
                  <Field
                    name="leadsConsent"
                    label={leadsNote}
                    component={CheckboxField}
                  />
                )}
              </div>
            )}
            <div style={{ width: 300 }}>
              <Field
                name="cups"
                component={CUPSField}
                style={{ width: "100%" }}
                onUpdate={(value) => handleCUPSUpdate({ ...values, cups: value })}
                cupsAvailability={cupsInvalid}
              />
              <div className="helper-text">{t('common:text.contractation_cups_gas_helper')}</div>
            </div>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <Field name="address" component={AddressField} />
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  name="residenceType"
                  label={t('common:text.contractation_habitual_residence')}
                  component={CheckboxField}
                />
              </Grid>
              {!values.residenceType &&
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    name="cnae"
                    component={CNAEField}
                    label={t('common:text.contractation_cnae')}
                    placeholder={t('common:text.contractation_cnae_hint')}
                  />
                </Grid>
              }
              {comerOriginEnabled &&
                <Grid item xs={12} md={6} lg={4}>
                  <FlexRow>
                    <Field
                      name="comerOrigin"
                      component={ComerOriginSelectField}
                      onAvailableComersUpdate={handleAvailableComersUpdate}
                      gas={true}
                    />
                  </FlexRow>
                </Grid>
              }
            </Grid>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <FlexRow>
              <Field
                name="invoice"
                component={FileUploadField}
                min={1}
                max={3}
                label={t('common:text.contractation_last_invoice')}
                hint={t('common:text.contractation_last_gas_invoice_helper')}
                anotherLabel={t('common:text.contractation_last_invoice_add')}
                removeLabel={t('common:text.remove')}
              />
            </FlexRow>

            <div style={{ marginTop: 25 }}>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting || validating || (cupsInvalid !== null)}
              >
                {
                  submitting || validating ? (
                    <CircularProgress size={25} />
                  ) : null
                }
                {t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutHome);
