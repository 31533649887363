import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import FormAboutHome from "./FormAboutHome";
import FormAboutYou from "./FormAboutYou";
import FormPayment from "./FormPayment";
import FormAboutContract from "./FormAboutContract";
import FormAboutGasContract from "./FormAboutGasContract";
import Confirm from "./Confirm";
import Confirmed from "./Confirmed";
import Settings from "../../settings";
import _ from "lodash";

const NewContractCurrentStep = ({hasGas, handleSubmit}) => {
  const dispatch = useDispatch();
  const elecNewContract = useSelector((state) => state.newElectricityContract);

  const handleGoBack = () => {
    if (elecNewContract.leadIndex !== 0) {
      dispatch(actionCreators.resetLeadsErrors());
      dispatch(actionCreators.setLeadIndex(elecNewContract.leadIndex - 1));
    }
  };

  const steps = [
    <FormAboutHome
      onSubmit={(values) => handleSubmit(values, "about-home", 1)}
      gas={hasGas}
      selectComerOrigin={_.get(Settings, 'newContract.selectComerOrigin', false)}
    />,
    <FormAboutYou
      onSubmit={(values, isFormValid) => handleSubmit(values, "about-you", 2, isFormValid)}
      onGoBack={handleGoBack}
      gas={hasGas}
    />,
    <FormAboutContract 
      onSubmit={(values) => handleSubmit(values, "about-contract", 3)}
      onGoBack={handleGoBack}
    />,
    <FormPayment 
      onGoBack={handleGoBack} 
      onSubmit={(values) => handleSubmit(values, "payment", 4)} 
    />,
    <Confirm 
      onGoBack={handleGoBack} 
      onSubmit={(values) => handleSubmit(values, "confirm", 5)} 
    />,
    <Confirmed 
      onGoBack={handleGoBack} /> 
  ];
  
  if (hasGas) {
    steps.splice(2, 0,
      {
        content: (
          <FormAboutGasContract
            onGoBack={handleGoBack}
            onSubmit={(values) => handleSubmit(values, "about-gas", 3)}
            selectComerOrigin={_.get(Settings, 'newContract.selectComerOrigin', false)}
          />
        ),
      },
    );
  }

  return steps[elecNewContract.leadIndex];

}

export default NewContractCurrentStep;