import { useState } from 'react';
import { i18n } from "../../config";

import ContractsList from './ContractsList';
import ContractsListGas from './ContractsListGas';
import Settings from "../../settings";

import { Tab, Box } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import GasIcon from '../../assets/GasIcon';
import LightIcon from '../../assets/LightIcon';

const ContractsTabs = () => {
  const [contractType, setContractType] = useState("electricity");

  const handleChange = (event, value) => {
    setContractType(value);
  };

  const showEmptyTabs = Settings.features?.showEmptyContractsInvoices;

  return <>
    { Settings.features?.newElectricityContract && 
      Settings.features?.newGasContract && 
      showEmptyTabs ?
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={contractType}>
            <TabList className="tabs" onChange={handleChange}>
              <Tab
                label={i18n.t('common:text.contracts_tab_title_electricty')}
                icon={<LightIcon className="light-icon" />}
                className={"tab tab-electricity selected-tab-" + contractType}
                value="electricity"
              />
              <Tab
                label={i18n.t('common:text.contracts_tab_title_gas')}
                icon={<GasIcon className="gas-icon" />}
                className={"tab tab-gas selected-tab-" + contractType}
                value="gas"
              />
            </TabList>
            <TabPanel value={"electricity"}>
              <ContractsList />
            </TabPanel>
            <TabPanel value={"gas"}>
              <ContractsListGas />
            </TabPanel>
          </TabContext>
        </Box>
    :
      ( Settings.features?.newGasContract || 
        Settings.features?.newElectricityContract ?
          <>
            {Settings.features?.newGasContract && 
              withNavigation(ContractsListGas)}
            {Settings.features?.newElectricityContract && 
              <ContractsList />}
          </>
        :  
          <div>Please configure a contract type.</div>
      )
    }
  </>
}

export default ContractsTabs;