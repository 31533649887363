import common_ca from './ca/common.json';
import common_es from './es/common.json';
import common_en from './en/common.json';
import custom_ca from '../overrides/i18n/ca.json';
import custom_es from '../overrides/i18n/es.json';
import custom_en from '../overrides/i18n/en.json';
import { getUrlParams } from "../utils/misc";
import { decodeJwt } from 'jose'
import store from "../store";
import { default as cookiesUtils } from '../utils/cookies';
import Settings from "../settings";
import _ from 'lodash';

const PARTNER_LANGUAGES = [
  { localeCode: "ca-ES", langCode: "ca", displayName: "Català", displayShort: "CAT" },
  { localeCode: "es-ES", langCode: "es", displayName: "Español", displayShort: "ES" },
  { localeCode: "en-US", langCode: "en", displayName: "English", displayShort: "EN" },
  { localeCode: "gl-ES", langCode: "gl", displayName: "Galego", displayShort: "GL" },
  { localeCode: "fr-FR", langCode: "fr", displayName: "Français", displayShort: "FR" },
  { localeCode: "de-DE", langCode: "de", displayName: "Deutsch", displayShort: "DE" },
];

const updateTranslationsWithCustom = () => {
  _.merge(common_ca, custom_ca);
  _.merge(common_es, custom_es);
  _.merge(common_en, custom_en);
}

updateTranslationsWithCustom();

export const resources = {
  'ca-ES': {
    common: common_ca,
  },
  'es-ES': {
    common: common_es,
  },
  'en-EN': {
    common: common_en,
  },
};


export const getVerifiedLanguage = (lang, separationChar="-") => {
  const LANG_SUFFIX = {
    "es": "ES",
    "ca": "ES",
    "en": "US"
  }
  let proccessedLang = null;

  if (!lang) { // INFO: Set a default that will not pass the sufix validation
    lang = "";
  }

  const prefix = lang.substring(0,2);
  const sufix = LANG_SUFFIX[prefix];
  if (sufix) { // INFO: If no suffix found mean the lang is malformatted or unexistent language
    proccessedLang = `${prefix}${separationChar}${sufix}`;
  } else if (lang !== Settings.language.default) {
    // INFO: Try again with the default language
    return getVerifiedLanguage(Settings.language.default)
  }

  return proccessedLang
}

const getLangCode = (locale) => {
  let lang = "";
  if (locale) {
    lang = locale.indexOf('_') > 0 ? locale.replace('_', '-')
      : locale.indexOf('-') > 0 ? locale
      : Settings.language.partner[0] //buscar-lo a la llista dels que tenim
  }

  return getVerifiedLanguage(lang);
}

const DEFAULT_LANGUAGE = Settings.language.default;

export const getLangByToken = (token) => {
  if (!token)
    return null;
  try {
    const token_values = decodeJwt(token);
    return token_values.locale;
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
}

const getLangByUrlParam = () => {
  try {
    const urlParams = getUrlParams();
    return (urlParams && urlParams.lang && getLangCode(urlParams.lang)) || null;
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
};

const getLangByCookies = () => {
  let lang = cookiesUtils.getLang();
  if (typeof lang === 'string') {
    return getVerifiedLanguage(lang);
  }
  return null;
};

export const getLangByBrowser = () => {
  try {
    return navigator.language;
  } catch (e) {
    console.debug('ERROR getting browser language');
    return null;
  }
}

export const getLang = () => {
  // First try to get the lang from url params
  let lang = getLangByUrlParam();

  // Second try to get the lang by cookies
  if (lang === null) {
    lang = getLangByCookies();
  }

  // Third try to get the lang from auth/token
  if (lang === null) {
    const auth = store.getState().auth;
    const token = (store.getState().auth === undefined) ? null : store.getState().auth.token;
    
    if (auth && token) {
      lang = getLangByToken(token);
    }
  }

  // Fourth try to get the lang from the browser
  if (lang === null) {
    lang = getLangByBrowser();
  }

  // Get the correct localeCode, if it is for example "en" -> "en-US"
  const languagesData = PARTNER_LANGUAGES.filter(
    langs => Object.values(langs).includes(lang)
  );
  if (languagesData.length > 0 && Settings.language.partner.includes(languagesData[0].localeCode)) {
      lang = languagesData[0].localeCode;
  } else {
    lang = null;
  }

  // If all else fails, use the default language from the settings.yml
  if (lang === null) {
    lang = DEFAULT_LANGUAGE;
  }

  cookiesUtils.saveLang(lang);
  return lang;
}

export const getAvailablePartnerLangs = () => {
  const enabledLangs = _.get(Settings, "language.partner", []);
  return PARTNER_LANGUAGES.filter(
    (partnerLang) => enabledLangs.indexOf(partnerLang.localeCode) !== -1
  );
};

export const getAvailableResources = () => {
  let langs = getAvailablePartnerLangs();
  // langs = langs.map(lang => lang.localeCode.split('_')[0].toLowerCase());
  return langs;
};