import { useTranslation } from "react-i18next";
import { GetApp } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { exportInvoice } from "../../actions/invoices";
import { dayjs } from "../../config";
import Settings from "../../settings";
import PaymentDialogButton from "./PaymentDialog";
import StripePaymentDialog from "./StripePaymentDialog";
import { DownloadButton } from "./DownloadButton";
import { Skeleton } from '@mui/material';


// Payment provider (default is stripe)
const paymentProvider = _.get(Settings, "paymentProvider", "stripe");

// Stripe Key
const stripeKey = _.get(Settings, "stripe.key", null);

const useInvoiceTable = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const { i18n, t } = useTranslation();

    const tableHead = [
        {
          title: t('common:text.invoices_invoice_number'),
          key: "num"
        }, {
          title: t('common:text.invoices_date'),
          key: "date"
        }, {
          title: t('common:text.invoices_address'),
          key: "address"
        }, {
          title: t('common:text.invoices_import'),
          key: "import"
        }, {
          title: t('common:text.invoices_payed'),
          key: "paid"
        }, {
          title: t('common:text.invoices_actions'),
          key: "actions"
        }
    ]

    const computeRow = (invoice) => {

      const isInvoicePaid = invoice.state === "paid"
      let paidDisplay = isInvoicePaid ? 
        t('common:text.invoice_paid') : <strong>{t('common:text.invoice_not_paid')}</strong>;
    
      if (isInvoicePaid && invoice.rectificative_type === "A") {
        paidDisplay = <strong>{t('common:text.invoice_refunded')}</strong>;
      }
      if (invoice.amount_pending) {
        if (invoice.amount_pending != invoice.amount_total) {
          paidDisplay = <strong>{t('common:text.invoices_parcial_payment')}</strong>;
        }
      }
    
      // TODO: Implement stripe
      //   // We enable a link to show the pending state history only if there
      //   // is more than one item to show.
      //   if (
      //     invoice.pending_state_history &&
      //     invoice.pending_state_history.length > 1
    
      //   ) {
      //     paidDisplay = (
      //       <div>
      //         <PaymentDialogButton invoice={invoice} />
      //       </div>
      //     );
      //   }
      // }
      // let payment = null;
      // switch (paymentProvider) {
      //   case "stripe":
      //     if (stripeKey) {
      //       payment = (
      //         <StripePaymentDialog
      //           invoice={invoice}
      //           onPaymentSuccessful={() => this.refreshData()}
      //         />
      //       );
      //     }
      //     break;
      //   default:
      //     break;
      // }
    
      return {
        "id": invoice.id,
        "num": invoice.number,
        "date:": dayjs(invoice.date_invoice).format('L'),
        "import": `${invoice.rectificative_type === 'A' ? '-' : ''}${invoice.amount_total}`,
        "paid": paidDisplay,
        "actions": <>
          <div>
            {<DownloadButton 
              invoiceId={invoice.id} 
              type={"Multipoint"} 
              onDownload={
                () => dispatch(exportInvoice(token, invoice.id, 'invoices_multipoint/', 2))
              } 
            />}
            {/* {invoice.amount_debt > 0 ? payment : null} */}
          </div>
        </>
      };
    }

    const generateTableSkeleton = (rows) => {
        return Array.from(Array(rows)).map((row, i) => {
            let newSkeletonRow = {id: i};
            tableHead.map((headerEl, j) => newSkeletonRow[headerEl.key] = <Skeleton variant="text" key={j + "" + i} />);
            return newSkeletonRow;
        });
    }

    return {
        tableHead,
        computeRow,
        generateTableSkeleton
    }

}

export default useInvoiceTable;