import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as newElectricityContractActions from "../../actions/newElectricityContract";
import * as newGasContractActions from "../../actions/newGasContract";
import queryString from "query-string";

import { Step, Stepper, StepLabel, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { i18n } from "../../config";
import Settings from "../../settings";
import Alert from "../Alert";
import HelpMessage from "../HelpMessage";
import NewContractCurrentStep from "./NewContractCurrentStep";
import NewContractStepTabs from "./NewContractStepTabs";
import { collectFormData } from "./form-data";
import { useLocation } from "react-router-dom";


const NewElectricityContract = ({gas}) => {
  const stepLimit = 6;

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const elecNewContract = useSelector((state) => state.newElectricityContract);
  const gasNewContract = useSelector((state) => state.newGasContract);
  
  const dispatch = useDispatch();
  const location = useLocation();

  const phoneLink = _.get(Settings, "organization.phone_crm", "").replace(/\s/g, '');
  const phone = _.get(Settings, "organization.phone_crm", "");

  const shouldSaveLeads = () => {
    let r = _.get(Settings, "features.leads", false) &&
      (elecNewContract.leadsConsent || isAuthenticated) &&
      elecNewContract.leadIndex !== stepLimit;
    return r
  }

  const shouldCheckDNI = () => {
    return _.get(Settings, "contract.shouldCheckDNI", false);
  }

  useEffect(() => {
    const { promocode } = queryString.parse(location.search, {arrayFormat: 'comma'});

    if (Settings?.features?.promotionalCode && promocode) {
      dispatch(newElectricityContractActions.validateCoupon(promocode));
    }

    dispatch(newElectricityContractActions.fetchContractationConfig());
    
    import("../../overrides/hooks/contractation").then(
      ({chatHook}) => {
        if (typeof chatHook === "function" && !!chatHook()) {
          document.body.appendChild(chatHook());
        }  
      }
    );
  }, []);

  const shouldCheckMorosity = () => {
    return _.get(Settings, "contract.shouldCheckMorosity", false);
  }

  const collectData = (values, step) => {
    const collectedData = collectFormData(values);
    collectedData.step = step;

    let gasCollectedData = undefined;
    if (gas && gasNewContract.cups) {
      gasCollectedData = collectFormData({
        ...elecNewContract.electricity,
        cups: gasNewContract.cups,
        comerOrigin: gasNewContract.comerOrigin,
        accessTariff: gasNewContract.accessTariff,
        selectedProduct: gasNewContract.selectedProduct,
        invoice: gasNewContract.invoice,
        owner: {
          ...elecNewContract.electricity.owner,
          changeOwner: gasNewContract.changeOwner,
        },
      });
      gasCollectedData.step = step;
    }
    return { collectedData, gasCollectedData };
  }

  const dispatchFormData = (leadName, values) => {
    switch (leadName) {
      case "about-home":
        dispatch(newElectricityContractActions.setAboutHomeData(values));
        break;
      case "about-you":
        dispatch(newElectricityContractActions.setAboutYouData(values));
        break;
      case "about-contract":
        dispatch(newElectricityContractActions.setAboutContractData(values));
        break;
      case "payment":
        dispatch(newElectricityContractActions.setPaymentData(values));
        break;
      case "about-gas":
        dispatch(newGasContractActions.submitAboutContractData(values)); 
        break;
      default:
    }
  }

  const handleSubmit = async (values, leadName, step) => {
    dispatch(newElectricityContractActions.resetLeadsErrors());
    const { collectedData, gasCollectedData } = collectData(values, step);

    dispatchFormData(leadName, values);
    
    if (leadName === "confirm") {
      collectedData.confirm = true;
      gasCollectedData.confirm = true;
      dispatch(newElectricityContractActions.setConfirmData(values));
      dispatch(newElectricityContractActions.confirmNewContract(collectedData));
      dispatch(newGasContractActions.confirmNewContract(gasCollectedData));
    } else if (shouldSaveLeads()) {
      dispatch(newElectricityContractActions.sendElectricityLeads(collectedData, { 
        "gasData": gasCollectedData,
        "step": step,
        "newLeadIndex": elecNewContract.leadIndex + 1,
        "checkSolvencia": (leadName === "about-you") ? shouldCheckMorosity() : false,
        "checkDNI": (leadName === "about-you") ? shouldCheckDNI() : false,
      }));
    }
  }

  return (
    <div className="form-contractation">
      <Box mb={2}>
        <Typography variant="h4" style={{marginBottom: 10}}>
          {gas ? 
          i18n.t('common:text.contractation_dual_title') : 
          i18n.t('common:text.contractation_title')
          }
        </Typography>

        {_.get(Settings, 'newContract.helpMessage', false) &&
          <HelpMessage
            message = {
              i18n.t('common:text.contractation_help_message', { phone: phone, phoneLink: phoneLink, })
            }
          />
        }
      </Box>
      <NewContractStepTabs hasGas={gas}/>

      <NewContractCurrentStep 
        hasGas={gas} 
        handleSubmit={handleSubmit}
      />
      
      { elecNewContract.leadErrors.badScore && 
        <Alert severity={"error"}>
          {i18n.t("common:text.contractation_leads_bad_score")}
          <br></br>
          {i18n.t("common:text.contractation_leads_bad_score_thanks")}
        </Alert>
      }
      { elecNewContract.leadErrors.dniInvalid &&
        <Alert severity={"error"}>
          {i18n.t("common:text.contractation_leads_dni_invalid")}
        </Alert>
      }
      { elecNewContract.leadErrors.genericError &&
        <Alert severity={"error"}>
          {i18n.t("common:text.contractation_leads_generic_error")}
        </Alert>
      }
      
    </div>
  );
};

export default NewElectricityContract;
