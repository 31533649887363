import React from 'react';
import { i18n } from "../../config";
import { Button, Box, Typography } from "@mui/material"



const HelpPanel = (props) => {

    return (
        <div className="help-panel">
          <Box>
            <Typography variant="h5">{i18n.t('common:text.help_panel_title')}</Typography>
          </Box>
          <p>{i18n.t('common:text.help_panel_text')}</p>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={props.onClick}
          >
            {i18n.t('common:text.help_panel_button')}
          </Button>
        </div>
    );
}

export default HelpPanel;