import { i18n } from "../config/i18n";
import Settings from "../settings";

export const locale_code = i18n.t('common:locale.code'); // TODO We should remove this
export const login_url = i18n.t('common:url.login');
export const connect_url = i18n.t('common:url.connect');
export const home_url = i18n.t(`common:url.${Settings?.homepage ?? "invoices"}`);

export const contraction_url = (() => {
    switch (Settings.features.newContractType) {
      case 'electricitat':
        return 'common:url.newElectricityContract';
      case 'gas':
        return 'common:url.newGasContract';
      case 'dual':
        return 'common:url.newDualContract';
      default:
        return 'common:url.newElectricityContract';
    }
  })()