import { Step, StepLabel, Stepper } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Settings from "@/settings";

const NewContractStepTabs = ({ hasGas }) => {
  const { t } = useTranslation();
  const elecNewContract = useSelector((state) => state.newElectricityContract);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => setScreenWidth(window.innerWidth)
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  let preCompiledSteps = [
    <Step>
      <StepLabel>{t('common:text.contractation_about_home')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{t('common:text.contractation_about_you')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{t('common:text.contractation_about_contract')}</StepLabel>
    </Step>,
    hasGas && (
      <Step>
        <StepLabel>{t('common:text.contractation_about_gas_contract')}</StepLabel>
      </Step>
    ),
    <Step>
      <StepLabel>{t('common:text.contractation_about_payment')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{t('common:text.contractation_confirmation')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{t('common:text.contractation_confirmed')}</StepLabel>
    </Step>
  ];

  if (Settings?.newContract?.businessesHaltContractation && elecNewContract.company) {
    preCompiledSteps.splice(2, hasGas ? 4 : 3);
  }


  useEffect(() => {
    if ( elecNewContract.leadIndex == preCompiledSteps.length-1 ) {
      // if (cookies.marketingConsentGiven()){
      import("../../overrides/hooks/contractation").then(
        ({trackingHook}) => {
          if (typeof trackingHook === "function" && !!trackingHook()) {
            let content =  trackingHook();
            if (content) {
              content = content.replace("<insert order id value here>", props.electricity.cups);
              document.head.prepend(document.createRange().createContextualFragment(content));
            }
          }  
        }
      );
    }
    // }

    // eslint-disable-next-line
  }, [elecNewContract.leadIndex]);

  return (
    <Stepper activeStep={elecNewContract.leadIndex} orientation={screenWidth > 996 ? "horizontal" : "vertical"}>
      {preCompiledSteps}
    </Stepper>
  );
}

export default NewContractStepTabs;