import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';

import { 
  editContract,
  viewContract,
  fetchContracts,
  setPhone,
  setEmail,
  filterContractsInvoicesRequest,
  messageContract,
  exportContract
} from '../../../actions/contracts';

import { 
  updateVirtualBatteryTypes, 
  fetchVirtualBatteryTypes,
} from '../../../actions/bateriaVirtual';

import { LoadingAnimation } from '../../LoadingAnimation';

import {
  Card, CardContent, CardActions, CardHeader, Typography, Box, CircularProgress,
  Grid, FormControl, Select, Switch, MenuItem, Button, Dialog,
  DialogContent, DialogActions,TableCell, Table, TableBody, TableHead, TableRow,
  TableContainer
} from '@mui/material';
import { Feedback, FlashOn, GetApp,
  Visibility, Create, Receipt, AccountBalance,
  PropaneSharp, LabelImportant } from '@mui/icons-material';
import {SmartTable} from "../../SmartTable";
import TextFieldReadOnly from "../../TextFieldReadOnly";
import CCHChart from "../../CCHChart";
import ContractNoCesion from "../ContractNoCesion";
import ContractElectronicNotification from "../ContractElectronicNotification";
import ChangeAddress from "../../ChangeAddress";
import ChangeIban from "../../ChangeIban";
import ChangePower from '../../ChangePower';
import CustomizeDoubleTariff from '../../CustomizeDoubleTariff';
import CustomizeHappyHours from '../../CustomizeHappyHours';
import CustomizeHappyDay from '../../CustomizeHappyDay';
import TextFieldEdit from "../../TextFieldEdit";
import Settings, {features} from "../../../settings";
import { dayjs } from "../../../config";
import { locale_code } from "../../../constants/i18n";
import ReclamATC from "../../ReclamATC";
import InvoiceOnline from "./InvoiceOnline";
import BateriaVirtualDialogOpenButton from '../../BateriaVirtual/BateriaVirtualDialogOpenButton';
import _ from 'lodash';
import {Notification} from "../../Notification";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VirtualBatteryDownload from "../../VirtualBattery/VirtualBatteryDownload";
import ChangePercentageVirtualBatteryDialogOpenButton from '../../VirtualBattery/ChangePercentageVirtualBattery/ChangePercentageVirtualBatteryDialogOpenButton';
import MoveVirtualBatteryDialogOpenButton from '../../VirtualBattery/MoveVirtualBattery/MoveVirtualBatteryDialogOpenButton';
import AttachmentsCard from '@/components/Contract/AttachmentsCard';


const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  entry: {
    marginBottom: '20px',
  },
  div: {
    overflowX: 'auto',
  },
  customWidth: {
    width: 150,
  },
  iconBefore: {
    marginRight: '10px',
  },
  modifyVirtualBatteryPercentage: {
    textAlign: "center"
  },
};

const ContractDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const contracts = useSelector(state => state.contracts.items);
  const contract = useSelector(state => state.contracts.view_contract);
  const token = useSelector(state => state.auth.token);
  const profile = useSelector(state => state.profile);
  const contractLoaded = useSelector(state => state.contracts.loading);
  const isFetching = useSelector(state => state.contracts.isFetching);
  const error = useSelector(state => state.contracts.error);
  const errorMessage = useSelector(state => state.contracts.data);
  const downloadingContract = useSelector(state => state.contracts.downloading_contract);
  const editFormOpen = useSelector(state => state.contracts.editFormOpen);
  const message = useSelector(state => state.contracts.message);
  const virtualBatteryTypes = useSelector(state => state.bateriaVirtual.virtualBatteryTypes);

  const [ formContent, setFormContent ] = useState(null);
  const [ dialogReclamOpen, setDialogReclamOpen ] = useState(false);
  const [ dialogSipsOpen, setDialogSipsOpen ] = useState(false);
  const [ valueBatteryModeSelect, setValueBatteryModeSelect ] = useState('no_aplicar');

  const changeDialogSipsOpen = (value) => {
    setDialogSipsOpen(value);
  };

  // Select
  const handleBatteryModeChange = (value, bateria_id) => {
    setValueBatteryModeSelect(value)
    dispatch(updateVirtualBatteryTypes(value, bateria_id));
  }

  useEffect(() => {
    if (!contracts.length) {
      dispatch(fetchContracts());
    }
    
  }, []);

  useEffect(() => {
    if (!contract && contracts.length) {
      dispatch(viewContract(contracts[0]));
      if (Settings?.features?.showVirtualBattery ?? true) {
        dispatch(fetchVirtualBatteryTypes());
      }
    }
  }, [contracts])

  const dialogClose = () => {
    dispatch(editContract(false));
  };

  const changePower = () => {
    dispatch(editContract(true));
    setFormContent(
      <ChangePower power_periods={contract.contracted_power_period}/>
    );
  };

  const handleContractChange = (event) => {
    let contract = contracts.filter((c) => c.id === event.target.value)[0];
    dispatch(viewContract(contract));
  };

  const handleUpdateTariffCustomization = async () => {
    dispatch(fetchContracts(token, true, 0, null));
    dispatch(editContract(false));
  };

  const changeIBAN = () => {
    dispatch(editContract(true));
    setFormContent(
      <ChangeIban actual={contract.bank_account}/>
    )
  }

  const onUpdatePhone = (value) => {
    dispatch(setPhone(value));
  };

  const onUpdateEmail = (value) => {
    dispatch(setEmail(value));
  };

  const changeNotificationAddress = () => {
    dispatch(editContract(true));
    setFormContent(
     <ChangeAddress actual={contract.notification_address}/>
    );
  };

  const goToFilteredInvoices = () => {
    dispatch(filterContractsInvoicesRequest(contract, contract.name));
    navigate(t('common:url.contracts_invoices', {contractName: contract?.name}));
  };

  const JSON_to_arrays = (data_servicios) => {
    const header =
      [
        {
          title: t('common:text.contract_name_of_service'),
        },
        {
          title: t('common:text.contract_name_of_service_start_date'),
        },
        {
          title: t('common:text.contract_name_of_service_end_date'),
        }
      ]
    let content = [];
    content = data_servicios.map( function(data_servicio, index) {
      if (data_servicio === undefined) return []
      const values = [
        data_servicio.nom || "",
        dayjs(data_servicio.data_inici).format("l") || "",
        data_servicio.data_fi ?  dayjs(data_servicio.data_fi).format("l") : "-",
      ]

      return values;
    });

    return {
      header,
      content
    };
  }

  const renderContract = () => {

    const iban = contract.bank_account ? contract.bank_account.printable_iban : t('common:text.contract_no_domiciled');
    let servicios = contract.services ?? [];
    let bonosSociales = (contract.bonos_sociales !== null && contract.bonos_sociales !== undefined) ? contract.bonos_sociales : [];
    let data_servicios = servicios.concat(bonosSociales);
    const adaptedData = data_servicios && JSON_to_arrays(data_servicios);

    const reclamActions = [
      <Button
        key={'close'}
        variant={'text'}
        color={'primary'}
        onClick={() => setDialogReclamOpen(false)}
      >
        {t('common:text.contract_close_dialog')}
      </Button>,
    ];
    const dialogReclam = (
      <Dialog
        open={dialogReclamOpen}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogContent>
          <ReclamATC value={contract}/>
        </DialogContent>
        <DialogActions>
          {reclamActions}
        </DialogActions>
      </Dialog>
    );

    const notifyAddr = _.get(contract, 'notification_address', {});

    const contractedPowers = (contract.contracted_power_period && contract.contracted_power_period.length) ? contract.contracted_power_period.reduce((accumulator, currentValue) => {
      return `${accumulator} ${currentValue.period}: ${currentValue.power.toLocaleString(locale_code)} kW`;
    }, '') : '';

    const selectContractField = (
      <FormControl>
        <Select
          value={contract.id}
          onChange={handleContractChange}
        >
          {contracts.map(contract => (
              <MenuItem key={contract.id} value={contract.id}> {contract.name} </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )

    return (
      <>
        <Dialog
          open={editFormOpen}
          onClose={dialogClose}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogContent>
            {formContent}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {dialogReclam}
        <>
          <Box mb={2}>
            <Typography variant='h4'>{t('common:text.contract_title')}</Typography>
          </Box>
          <Card style={styles.entry}>
            <CardContent>
              <Box pb={2}>
                <Typography variant="h5">
                  {t('common:text.contract_data_contract')}{selectContractField}
                </Typography>
              </Box>
              {features?.noCesionSips &&
                <div>
                  <ContractNoCesion
                    contract={contract}
                    changeDialogSipsOpen={changeDialogSipsOpen}
                    open={dialogSipsOpen}
                  />
                </div>
              }
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_owner')}
                  >
                    {contract?.owner_partner.name}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_payer')}
                  >
                    {contract?.fiscal_partner?.name}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly label={t('common:text.contract_contracted_power')}>
                    {contractedPowers}

                    {features?.powerChange && !Settings?.contract?.horizontalButtons &&
                      <span>
                        <Button
                          variant={'text'}
                          color={'primary'}
                          disabled={contract.state !== 'activa' || !contract.atr_available}
                          title={t('common:text.contract_power_modification')}
                          onClick={() => changePower()}
                        > 
                            {t('common:text.contract_modification')}
                        </Button>
                        {!contract?.atr_available &&
                          <div style={{fontSize: '12px'}}>
                            <em>{t('common:text.contract_process_currently')}</em>
                          </div>
                        }
                      </span>
                    }
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div>
                    {/* Si no te nom comercial llista de preus {_.get(contract, 'pricelist.name', '')} */}
                    <TextFieldReadOnly label={t('common:text.contract_contracted_product')}>
                      {contract?.pricelist.nom_comercial ? 
                        contract?.pricelist?.nom_comercial : contract?.pricelist?.name
                      }
                    </TextFieldReadOnly>

                    <CustomizeDoubleTariff
                      contractId={contract.name}
                      onUpdated={handleUpdateTariffCustomization}
                    />
                    <CustomizeHappyHours
                      contractId={contract.name}
                      onUpdated={handleUpdateTariffCustomization}
                    />
                    <CustomizeHappyDay
                      contractId={contract.name}
                      onUpdated={handleUpdateTariffCustomization}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly label={t('common:text.contract_payment_account')}>{iban}
                    {features?.ibanChange && !Settings?.contract?.horizontalButtons &&
                      <Button
                        variant={'text'}
                        color={'primary'}
                        disabled={(contract.state !== 'activa')}
                        title={t('common:text.contract_account_modification')}
                        onClick={() => changeIBAN()}
                      >
                        {iban !== t('common:text.contract_no_domiciled') ?
                          t('common:text.contract_modification') : t('common:text.contract_enable_domiciliation')}
                      </Button>
                    }
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_nif')}
                  >
                    {contract?.fiscal_partner.vat}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_fiscal_address')}
                  >
                    {contract?.fiscal_address?.full_address}
                  </TextFieldReadOnly>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box flexWrap="wrap" px={1} pb={1}>
                { !features?.disableDownloadContractReport &&
                  <Button
                    key={'download'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => dispatch(exportContract(token, contract.name))}
                    disabled={downloadingContract}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {(downloadingContract ? <CircularProgress size={25} style={styles.iconBefore} /> :
                      <GetApp style={styles.iconBefore}/>)}
                    {t('common:text.contract_download')}
                  </Button>
                }
                <Button
                  key={'invoices'}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() =>goToFilteredInvoices(contract)}
                  title={t('common:text.contract_related_invoices')}
                  style={{marginRight: 12, marginTop: 8}}
                >
                  {<Receipt style={styles.iconBefore}/>}
                  {t('common:text.contracts_view_invoices_button_button')}
                </Button>

                {features?.reclamATCContract &&
                  <Button
                    key={'reclamation'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setState({dialogReclamOpen: true})}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {<Feedback style={styles.iconBefore}/>}
                    {t('common:text.contract_create_atc_reclamation')}
                  </Button>
                }
                {features?.powerChange && Settings?.contract?.horizontalButtons &&
                  <Button
                    key={'changePower'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={contract.state !== 'activa' || !contract.atr_available}
                    title={t('common:text.contract_power_modification')}
                    onClick={() => changePower()}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {<FlashOn style={styles.iconBefore}/>}
                    {t('common:text.contract_power_modification')}
                  </Button>
                }
                {features?.noCesionSips && Settings?.contract?.horizontalButtons &&
                  <Button
                    key={'sips'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={contract.state !== 'activa' || !contract.atr_available || contract.no_cession_sips === 'active'}
                    title={t('common:text.sips_title')}
                    onClick={() => setState({dialogSipsOpen: true})}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {<Visibility style={styles.iconBefore}/>}
                    {t('common:text.sips_title')}
                  </Button>
                }
                {features?.ibanChange && iban !== t('common:text.contract_no_domiciled') 
                  && Settings?.contract?.horizontalButtons &&
                  <Button
                    key={'iban'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={(contract.state !== 'activa')}
                    title={
                      iban !== t('common:text.contract_no_domiciled') ? 
                        t('common:text.contract_account_modification') : t('common:text.contract_enable_domiciliation')}
                    onClick={() => changeIBAN()}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {<AccountBalance style={styles.iconBefore}/>}
                    {iban !== t('common:text.contract_no_domiciled') ? 
                      t('common:text.contract_account_modification') : t('common:text.contract_enable_domiciliation')}
                  </Button>
                }
              </Box>
            </CardActions>
          </Card>

          <Card style={styles.entry}>
            <CardContent>
              <Box pb={2}>
                <Typography variant="h5">
                  {t('common:text.contract_contact_data')}
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_contact_data_name')}>{notifyAddr.name}</TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_contact_data_address')}>{notifyAddr.full_address}</TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  {!notifyAddr.email &&
                    <TextFieldEdit 
                      field="email" 
                      label={t('common:text.contract_contact_data_email')}
                      onUpdated={onUpdateEmail} 
                      value={profile.data?.email}
                    />
                  }
                  {notifyAddr.email &&
                    <TextFieldReadOnly label={t('common:text.contract_contact_data_email')}>
                      {notifyAddr.email}
                      <InvoiceOnline/>
                      { Settings?.contract?.electronicNotificationButton &&
                        <ContractElectronicNotification/>
                      }
                    </TextFieldReadOnly>
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  {!notifyAddr.mobile &&
                    <TextFieldEdit 
                      field="phone" 
                      label={t('common:text.contract_contact_data_phone')}
                      onUpdated={onUpdatePhone}
                    />
                  }
                  {notifyAddr.mobile &&
                    <TextFieldReadOnly
                      label={t('common:text.contract_contact_data_phone')}
                    >
                      {notifyAddr.mobile}
                    </TextFieldReadOnly>
                  }
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box flexWrap="wrap" px={1} pb={1}>
                {features?.notificacionAddressChange &&
                  <Button
                    key={'modifyAddress'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={(contract.state !== 'activa')}
                    title={t('common:text.contract_modification')}
                    onClick={() => changeNotificationAddress()}
                    style={{marginRight: 12, marginTop: 8}}
                  >
                    {<Create style={styles.iconBefore}/>}
                    {t('common:text.contract_modification')}
                  </Button>
                }
              </Box>
            </CardActions>
          </Card>

          <Card style={styles.entry}>
            <CardContent>
              <Box pb={2}>
                <Typography variant="h5">
                  {t('common:text.contract_cups_info')}
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_cups')}
                  >
                    {contract?.cups?.name}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_cups_address')}
                  >
                    {contract?.cups?.full_address}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_distri')}
                  >
                    {contract?.dso?.name}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_contract_distri')}
                  >
                    {contract?.dso_reference}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly label={t('common:text.contract_cups_info_breakdown_num')}>
                    {contract.dso_phone && <a href={`tel:${contract.dso_phone}`}>{contract.dso_phone}</a>}
                  </TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_access_tariff')}>{_.get(contract, 'tariff.name')}</TextFieldReadOnly>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldReadOnly
                    label={t('common:text.contract_cups_info_ref')}>{_.get(contract, 'cups.ref_catastral', '')}</TextFieldReadOnly>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {_.size(contract.bateria_virtual) > 0 &&
            <Card style={styles.entry}>
              <div style={styles.wrapper}>
                <CardHeader title={ t("common:text.altHome_virtual_battery") } style={{flexGrow: 1}}/>
                <VirtualBatteryDownload bateries={contract.bateria_virtual}></VirtualBatteryDownload>
              </div>
              <TableContainer>
                <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('common:text.altHome_battery')}</TableCell>
                    <TableCell>€ {t('common:text.altHome_accumulated')}</TableCell>
                    <TableCell>{t('common:text.virtual_battery_percentage')}</TableCell>
                    <TableCell>{t('common:text.altHome_to_discount')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contract.bateria_virtual.map(
                    (bateria, idx) => {
                      if (!bateria.total_descompte) {
                        bateria.total_descompte = 0.0
                      }

                      // Crec que tot això del ncontracts no fa falta
                      let ncontracts = 0;
                      
                      for (let i = 0; i < contracts.length; i++) {
                        for (let j = 0; j < contracts[i].bateria_virtual.length; j++){
                          if (bateria.bateria.id == contracts[i].bateria_virtual[j].bateria.id){
                            ncontracts = ncontracts + 1;
                          }
                        }
                      }

                      let other_contract_batteries = 0
                      if (contract.state != "baixa"){
                        for (let i=0; i<contracts.length; i++){
                          for (let j=0; j<contracts[i].bateria_virtual.length; j++){
                            if (contracts[i].bateria_virtual[j].bateria.id != bateria.bateria.id
                              && contracts[i].bateria_virtual[j].bateria.name != bateria.bateria.name
                              && contracts[i].id != contract
                              && contracts[i].state != "baixa"
                              ){
                                other_contract_batteries += 1;
                            }
                          }
                        }
                      }

                      return (
                        Settings?.contract?.batteryRenderType === "toggle" ?
                          <TableRow key={idx}>
                            <TableCell>
                              <Box sx={{display: "flex"}}>
                                { bateria.es_origen && <span title={t('common:text.battery_origin_tooltip')}><LabelImportant/></span> }
                                {bateria.bateria.name}
                              </Box>
                            </TableCell>
                            <TableCell>{bateria.total_descompte} €</TableCell>
                            <TableCell>{bateria.pes_percentatge} %</TableCell>
                            <TableCell>
                              {virtualBatteryTypes &&
                                <Grid container justifyContent="flex-start" alignItems="center">
                                  <Typography
                                    inline variant="body1"
                                    style={{color: bateria.gestio_descomptes === "aplicar" ? "gray" : "black"}}
                                  >No</Typography>
                                  <Switch
                                    checked={bateria.gestio_descomptes === "aplicar"}
                                    disable={virtualBatteryTypes.loading}
                                    onChange={
                                      (e) => handleBatteryModeChange(
                                        e.target.checked ?
                                          virtualBatteryTypes.types[1].name //"aplicar"
                                          :
                                          virtualBatteryTypes.types[0].name, //"no_aplicar"
                                        bateria.bateria.id
                                      )
                                    }
                                  />
                                  <Typography
                                    inline variant="body1"
                                    style={{color: bateria.gestio_descomptes === "aplicar" ? "black" : "gray"}}
                                  >Sí</Typography>
                                </Grid>
                              }
                              </TableCell>
                              <TableCell style={styles.modifyVirtualBatteryPercentage}>
                                {ncontracts > 1 &&
                                  <ChangePercentageVirtualBatteryDialogOpenButton bateriaVirtual={bateria} />
                                }
                              </TableCell>
                              <TableCell style={styles.modifyVirtualBatteryPercentage}>
                                {other_contract_batteries > 0 &&
                                  <MoveVirtualBatteryDialogOpenButton bateriaVirtual={bateria} token={token}/>
                                }
                              </TableCell>
                          </TableRow>
                        :
                          <TableRow key={idx}>
                            <TableCell>
                              <Box sx={{display: "flex"}}>
                                { bateria.es_origen && <span title={t('common:text.battery_origin_tooltip')}><LabelImportant/></span> }
                                {bateria.bateria.name}
                              </Box>
                            </TableCell>
                            <TableCell>{bateria.total_descompte} €</TableCell>
                            <TableCell>{bateria.pes_percentatge} %</TableCell>
                            <TableCell>
                              <Select
                                disabled={virtualBatteryTypes.loading}
                                value={bateria.gestio_descomptes}
                                onChange={(event) => {
                                  handleBatteryModeChange(event.target.value, bateria.bateria.id)}}
                              >
                                {!_.isEmpty(virtualBatteryTypes.types) &&
                                  virtualBatteryTypes.types.map((p, idx) => {
                                    return  <MenuItem key={idx} value={p.name}>{p.description}</MenuItem>
                                  })
                                }
                              </Select>
                            </TableCell>
                            <TableCell style={styles.modifyVirtualBatteryPercentage}>
                              {ncontracts > 1 &&
                                <ChangePercentageVirtualBatteryDialogOpenButton bateriaVirtual={bateria} />
                              }
                            </TableCell>
                            <TableCell style={styles.modifyVirtualBatteryPercentage}>
                              {other_contract_batteries > 0 &&
                                <MoveVirtualBatteryDialogOpenButton bateriaVirtual={bateria} token={token}/>
                              }
                            </TableCell>
                          </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
              </TableContainer>
            </Card>
          }

          {_.get(adaptedData, 'content[0].length', 0) !== 0 &&
            <Card style={styles.entry}>
              <CardContent>
                <Box pb={2}>
                  <Typography variant="h5">
                    {t('common:text.contract_services')}
                  </Typography>
                </Box>
                <div>
                  <SmartTable
                    header={adaptedData.header}
                    data={adaptedData.content}
                  />
                </div>
              </CardContent>
            </Card>
          }
        </>

        <Card style={styles.entry}>
          <CardHeader title={t('common:text.contract_consumptions')}/>
          <CardContent>
            <CCHChart contractId={contract.name} token={token}/>
          </CardContent>
        </Card>

        <AttachmentsCard attachmentsList={contract.attachments}/>

        <Notification
          message={message}
          time={6000}
          open={true}
          onRequestClose={() => messageContract(null)}
        />

      </>

    );
  };

  if (contractLoaded === 'succeeded' && contract) {
    return renderContract();
  }
  return <LoadingAnimation/>;

}

export default ContractDetail;
