/* eslint new-cap: 0 */

import { useDispatch, useSelector } from "react-redux";
import { 
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

/* containers */
import LoginView from "./components/LoginView";
import ChangePasswordView from "./components/ChangePasswordView";
import LoadingView from "./components/LoadingView";
import ReclamATC from "./components/ReclamATC";
import CRMForm from "./components/CRMForm";
import UnsignedCRM from "./components/UnsignedCRM";
import { ErrorPage, ErrorPageProps } from "./components/ErrorPage";
import MaintenanceView from "./components/MaintenanceView";
import DinamicServerPage from "./components/ServerElements/DinamicServerPage";

import SignUpView from "./components/SignUpView";
import ContractsTabs from "./components/Contracts/ContractsTabs";
import ContractDetail from "./components/Contracts/ContractDetail";
import ContractDetailGas from "./components/Contracts/ContractDetailGas";
import InvoicesTabs from "./components/Invoices/InvoicesTabs";
import InvoicesMultipoint from "./components/Invoices/InvoicesMultipointContent";
import AltHome from "./components/AltHome/AltHomeView";
import NewElectricityContract from "./components/NewElectricityContract";
import NewGasContract from "./components/NewGasContract";
import UserResetPassword from "./components/UserResetPassword";
import CalculadoraPotencia from "./components/CalculadoraPotencia";
import PriceComparator from "./components/PriceComparator";
import GasPriceComparator from "./components/GasPriceComparator";
import { LoadingAnimation } from "./components/LoadingAnimation";

import Home from "./overrides/pages/Home";

import {default as untypedSettings} from "./settings";

import { useTranslation } from "react-i18next";
import RootPage from "./components/RootPage";
import { FC, ReactNode, useEffect } from "react";
import { RootState } from "./store";
import useI18n from "./hooks/useI18n";
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import authActions from "./actions/auth";
import { LanguageProvider } from "./components/LangProvider";
import { LayoutProvider } from "./components/LayoutProvider";
import { useNavigationItemsContext } from "./components/NavigationMenu/useNavigationItemsContext";

type RoutesSettings = {
  altHome?:{
    enabled: boolean;
  },
  powerCalculator?: {
    enabled: boolean;
  },
  comparator?: {
    enabled: boolean;
  },
  features?: {
    newElectricityContractation: boolean;
    newGasContractation: boolean;
    oidc: boolean;
    newContractType: "electricitat" | "gas" | "dual";
    enableServerElements: boolean;
  },
  newContract?: {
    authenticate: boolean;
  },
  newGasContract?: {
    authenticate: boolean;
  },
  newDualContract?: {
    authenticate: boolean;
  },
  maintenance?: {
    activated: boolean;
    starts?: string;
    ends?: string;
  }
}

type RouteProps = {
  children: ReactNode;
  path: string;
}

export const PrivateRoute: FC<RouteProps> = ({ children, path }) => {
  const { t } = useTranslation();
  const { currentPath, setCurrentPath, setCurrentPathProtection } = useNavigationItemsContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticating = useSelector((store: RootState) => store.auth.isAuthenticating);
  const isAuthenticated = useSelector((store: RootState) => store.auth.isAuthenticated);
  const isRedirectedAfterLogin = useSelector((store: RootState) => store.auth.isRedirectedAfterLogin);
  const {redirect, redirectSearchParams} = useSelector((store: RootState) => store.auth);
  const {statusCode, statusText, statusType} = useSelector((store: RootState) => store.auth);
  const searchParams = useSearchParams();
  
  useEffect(() => {
    setCurrentPath(path);
    setCurrentPathProtection("private");
    if (statusType !== null) {
      dispatch(authActions.clearError());
    }
  }, [path, searchParams[0]]);

  useEffect(() => {
    if (!isAuthenticating) {
      if ( isAuthenticated ) {

        // OpenID & ClientID
        if ( redirect && !isRedirectedAfterLogin) {
          dispatch(authActions.setRedirected());
          navigate(t(redirect) + "?" + (redirectSearchParams ?? ''));
        } 

        // Si no ha fet cap inici de sessió (és la única manera de tenir error de login)
      } else if (!(statusType === "error")){

        // Quan no hi ha oidc redirigeix a login
        // Quan hi ha oidc AuthenticationProvider s'encarrega de redirigir
        if (!untypedSettings.features?.oidc) {
          navigate(t("common:url.login"));
        }
      }
    }
  }, [isAuthenticating, isAuthenticated, statusType])

  if ( isAuthenticating ) {
    return <LoadingView />
  } else {

    // Si està autenticat i s'ha assignat el currentPath (per evitar el problema
    // de que si peta la vista no s'arriba a assignar el currentPath, ja que 
    // l'useEffect es fa DESPRÉS del primer render).
    if ( isAuthenticated ) {
      if (redirect && !isRedirectedAfterLogin) {
        return <LoadingView />
      }
      return children;
    } else {
      if (statusType === "error") {
        return <LayoutProvider path={"common:url.error"}>
            <ErrorPage errorCode={statusCode} message={statusText}/>
          </LayoutProvider>
      }
      return <LoadingView />
    }
  }

};


export const PublicRoute: FC<RouteProps> = ({ children, path }) => {
  const { t } = useTranslation();
  const { currentPath, setCurrentPath, setCurrentPathProtection } = useNavigationItemsContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticating = useSelector((store: RootState) => store.auth.isAuthenticating);
  const isAuthenticated = useSelector((store: RootState) => store.auth.isAuthenticated);
  const loginType = useSelector((store: RootState) => store.auth.type);
  const {statusCode, statusText, statusType} = useSelector((store: RootState) => store.auth);
  const searchParams = useSearchParams();

  useEffect(() => {
    setCurrentPath(path);
    setCurrentPathProtection("public");
    if (statusType !== null) {
      dispatch(authActions.clearError());
    }
    // Mirem el canvi dels searchParams[0] per quan 
    // hi ha la vista de l'error des d'una URL i 
    // es clica el botó del menú que va a la mateixa URL,
    // ja que treu els searchParams
  }, [path, searchParams[0]]);

  useEffect(() => {
    if ( isAuthenticated ) {
      navigate(t("common:url.invoices"));
    }
  }, [isAuthenticated, path]);

  // Casuística similar a la PrivateRoute
  if ( isAuthenticating && currentPath === path ) {
    if ( loginType == "credentials") {
      return children;
    } else {
      return <LoadingView />
    }
  } else {
    if ( !isAuthenticated ) {
      // Potser aquesta gestió es pot fer millor
      if ( currentPath === "common:url.login" && loginType == "credentials" ) {
        return children;
      }
      if (statusType === "error") {
        return <LayoutProvider path={"common:url.error"}>
            <ErrorPage errorCode={statusCode} message={statusText}/>
          </LayoutProvider>
      }
      return children;
    }
  }
};

export const UnprotectedRoute: FC<RouteProps> = ({ children, path }) => {
  const { setCurrentPath } = useNavigationItemsContext();
  const dispatch = useDispatch();
  const {statusType} = useSelector((store: RootState) => store.auth);
  const searchParams = useSearchParams();

  useEffect(() => {
    setCurrentPath(path);
    if (statusType !== null) {
      dispatch(authActions.clearError());
    }
    }, [path, searchParams[0]]);

  return children;
};

export const ErrorRoute: FC<ErrorPageProps> = ({...rest}) => {
  const { t } = useTranslation();
  const { currentPathProtection } = useNavigationItemsContext();
  const isAuthenticated = useSelector((store: RootState) => store.auth.isAuthenticated);
  const navigate = useNavigate();

  // Si es desloguegen quan estan en una pestanya d'error
  useEffect(() => {
    if ( currentPathProtection === "private" && !isAuthenticated ) {
      navigate(t("common:url.login"));
    }
  }, [isAuthenticated])

  return <ErrorPage {...rest}/>;
};

type HandleAuthRouteProps =  {
  children: ReactNode;
  path: string;
  protection: "private" | "public" | "unprotected";
}

const RouterConfiguration = () => {
  const Settings: RoutesSettings = untypedSettings;
  const { getAllLangsPath } = useI18n();

  const generateTranslatedRouteElements = ({
    path, 
    children, 
    protection,
  }: HandleAuthRouteProps) => {
    let translatedRoutes = getAllLangsPath(path).map(translatedPath => {
      const interpolatedPath = translatedPath.replace(/{{/g, ":").replace(/}}/g, "");

      if (protection === "private") {
        return {
          path: interpolatedPath,
          element: (
            <AuthenticationProvider>
              <LanguageProvider path={path}>
                <PrivateRoute  path={path}>
                  <LayoutProvider path={path}>
                    {children}
                  </LayoutProvider>
                </PrivateRoute>
              </LanguageProvider>
            </AuthenticationProvider>
          )  
        }
      }

      if (protection === "public") {
        return {
          path: interpolatedPath,
          element: (
            <AuthenticationProvider>
              <LanguageProvider path={path}>
                <PublicRoute path={path}>
                  <LayoutProvider path={path}>
                    {children}
                  </LayoutProvider>
                </PublicRoute>
              </LanguageProvider>
              </AuthenticationProvider>
          )  
        }
      }

      return {
        path: interpolatedPath,
        element: (
          <AuthenticationProvider>
            <LanguageProvider path={path}>
                <UnprotectedRoute path={path}>
                  <LayoutProvider path={path}>
                    {children}
                  </LayoutProvider>
                </UnprotectedRoute>
            </LanguageProvider>
          </AuthenticationProvider>
        )
      }
    });

    return translatedRoutes;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
      children: [
        {
          errorElement: <LayoutProvider path="common:url.error"><ErrorRoute/></LayoutProvider>,
          children: [
            { 
              index: true,
              element: (
                <AuthenticationProvider>
                  <LanguageProvider path="">
                    <PrivateRoute  path="">
                      <LayoutProvider path="">
                        <Home />
                      </LayoutProvider>
                    </PrivateRoute>
                  </LanguageProvider>
                </AuthenticationProvider>
              )
            },
            { 
              path: "*",
              element: (
                <AuthenticationProvider>
                  <LanguageProvider path="common:url.error">
                    <UnprotectedRoute path="">
                      <LayoutProvider path="common:url.error">
                        <ErrorRoute errorCode={404}/>
                      </LayoutProvider>
                    </UnprotectedRoute>
                  </LanguageProvider>
                </AuthenticationProvider>
              )
            },
            ...generateTranslatedRouteElements({
              path: "common:url.invoices",
              children: <InvoicesTabs showGas={true}/>,
              protection: "private",
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.contracts_invoices",
              children: <InvoicesTabs showGas={true}/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.invoices_multipoint",
              children: <InvoicesMultipoint />,
              protection: "private",
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.contracts",
              children: <ContractsTabs/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.contact",
              children: <CRMForm/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.reclamations",
              children: <ReclamATC/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.changePassword",
              children: <ChangePasswordView/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.contracts_detail",
              children: <ContractDetail/>,
              protection: "private"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.contracts_detail_gas",
              children: <ContractDetailGas/>,
              protection: "private"
            }),
            ...(!Settings?.features?.oidc ? generateTranslatedRouteElements({
              path: "common:url.login",
              children: <LoginView/>,
              protection: "public"
            }) : []),
            ...(Settings?.features?.oidc ? generateTranslatedRouteElements({
              path: "common:url.login",
              children: <LoadingView/>,
              protection: "private"
            }) : []),
            ...generateTranslatedRouteElements({
              path: "common:url.connect",
              children: <LoadingView/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.unsigned_contact",
              children: <UnsignedCRM/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.activate",
              children: <SignUpView/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.resetPassword",
              children: <UserResetPassword/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.powerCalculator",
              children: <CalculadoraPotencia/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.priceComparator",
              children: <PriceComparator/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.gasPriceComparator",
              children: <GasPriceComparator/>,
              protection: "public"
            }),
            ...generateTranslatedRouteElements({
              path: "common:url.activate",
              children: <SignUpView/>,
              protection: "public"
            }),
            ...(Settings?.altHome?.enabled ? generateTranslatedRouteElements({
              path: "common:url.altHome",
              children: <AltHome/>,
              protection: "unprotected"
            }) : []),
            ...(Settings?.powerCalculator?.enabled ? generateTranslatedRouteElements({
              path: "common:url.powerCalculator",
              children: <CalculadoraPotencia/>,
              protection: "public"
            }) : []),
            ...(Settings?.comparator?.enabled ? generateTranslatedRouteElements({
              path: "common:url.priceComparator",
              children: <CalculadoraPotencia/>,
              protection: "public"
            }) : []),
            ...(Settings?.features?.enableServerElements ? generateTranslatedRouteElements({
              path: "common:url.dynamic",
              children: <DinamicServerPage/>,
              protection: "private"
            }) : []),
            ...(Settings.features?.newElectricityContractation ? generateTranslatedRouteElements({
              path: "common:url.newElectricityContract",
              children: <NewElectricityContract gas={false}/>,
              protection: Settings.newContract?.authenticate ? "private" : "unprotected"
            }) : []),
            ...(Settings.features?.newContractType === "dual" ? generateTranslatedRouteElements({
              path: "common:url.newDualContract",
              children: <NewElectricityContract gas={true}/>,
              protection: Settings?.newDualContract?.authenticate ? "private" : "unprotected"
            }) : []),
            ...(Settings.features?.newGasContractation ? generateTranslatedRouteElements({
              path: "common:url.newGasContract",
              children: <NewGasContract/>,
              protection: Settings?.newGasContract?.authenticate ? "private" : "unprotected"
            }) : []),
            ...(Settings.features?.newGasContractation ? generateTranslatedRouteElements({
              path: "common:url.newGasContract",
              children: <NewGasContract/>,
              protection: Settings?.newGasContract?.authenticate ? "private" : "unprotected"
            }) : [])
          ]
        }
      ]
    }
  ]);

  const maintenanceRouter = createBrowserRouter([
    {
      path: "*",
      element: <Navigate to={"/"} />,
    },
    {
      path: "/",
      element: <LayoutProvider path="common:url.error"><ErrorRoute errorCode={503}/></LayoutProvider>,
    }
  ]);

  return <RouterProvider 
    router={ Settings.maintenance?.activated ? maintenanceRouter : router} />
};

export default RouterConfiguration;
