import _ from "lodash";
import React from 'react';
import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";
import { MenuItem, FormControl, InputLabel, Box, Typography,
  Select, TextField, FormHelperText, Grid, Divider } from "@mui/material"

export const validarReferenciaCatastral = (referenciaCatastral) => {
  // Valor por el que se debe multiplicar cada posición de cada subcadena
  const pesoPosicion = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1];
  const letraDc = 'MQWERTYUIOPASDFGHJKLBZX';

  // Sólo se comprueban las referencias catastrales con 20 carácteres alfanuméricos,
  // los dos últimos corresponden a los dígitos de control.
	if ((referenciaCatastral === undefined || referenciaCatastral === null) ||
      referenciaCatastral.length !== 20) {
    return -1;
	}
  referenciaCatastral = referenciaCatastral.toUpperCase();

  // Para calcular cada dígito de control se utilizan siguientes subcadenas
  const cadenaPrimerDC=(referenciaCatastral.substring(0,7) + referenciaCatastral.substring(14,18)).toUpperCase();
  const cadenaSegundoDC=(referenciaCatastral.substring(7,14) + referenciaCatastral.substring(14,18)).toUpperCase();

  const cadenasDC=[cadenaPrimerDC, cadenaSegundoDC];
  let dcCalculado='';

	cadenasDC.forEach((cadena) => {
	  let sumaDigitos=0;
    /*
        Para el cálculo de cada dígito de control, se deben de sumar cada
        uno de los carácteres de cada cadena.
        Si el carácter no es numérico el valor corresponde de la siguiente
        manera: A = 1, B = 2, ..., Z = 27.
    */
		cadena.split('').forEach((caracter, posicion) => {
      let valorCaracter=caracter;

      if(caracter>='A' && caracter<='N')
      {
          valorCaracter=caracter.charCodeAt()-64;
      }
      else if(caracter==='Ñ')
      {
          valorCaracter=15;
      }
      else if(caracter>'N')
      {
          valorCaracter=caracter.charCodeAt()-63;
      }
      sumaDigitos=(sumaDigitos + (valorCaracter*pesoPosicion[posicion]))%23;
		});

    // Valor del dígito de control calculado
    dcCalculado+=letraDc.charAt(sumaDigitos);
	});

	if (dcCalculado!==referenciaCatastral.substring(18,20)) {
    return 0;
	}
	return 1;

};

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zipcode: '',
      cities: [],
      city: {},
      states: [],
      state: {},
      tipovias: [],
      tv: {},
      nv: '',
      pnp: '',
      bq: '',
      es: '',
      pt: '',
      pu: '',
      aclarador: '',
      ref_catastral: '',
      errors: props.errors
    };
    if (this.props.value) {
      // console.log("constructor props", this.props.value);
      this.state = Object.assign(this.state, this.props.value);
    }
  }

  componentDidMount() {
    this.timeout = null;
    if (!this.props.readonly) {
      data_fetch_api_resource(null, 'geo/tipovias/').then((result) => {
        if (result && result.status === 200 && result.data.length > 0) {
          this.setState({tipovias: result.data});
        }
      });
      const {zipcode, city} = this.state;
      if (zipcode) {
        this.fetchZipcodes(zipcode);
      }
      if (city && city.ine) {
        this.fetchProvincies(city.ine.slice(0, 2));
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (state.zipcode !== props.value.zipcode) {
      newState.zipcode = props.value.zipcode || '';
    };
    if (state.tv !== props.value.tv) {
      newState.tv = props.value.tv || '';
    };
    if (state.nv !== props.value.nv) {
      newState.nv = props.value.nv || '';
    };
    if (state.pnp !== props.value.pnp) {
      newState.pnp = props.value.pnp || '';
    };
    if (state.bq !== props.value.bq) {
      newState.bq = props.value.bq || '';
    };
    if (state.pt !== props.value.pt) {
      newState.pt = props.value.pt || '';
    };
    if (state.pu !== props.value.pu) {
      newState.es = props.value.es || '';
    };
    if (state.state !== props.value.state) {
      newState.state = props.value.state || '';
    };

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.zipcode !== this.state.zipcode) {
      this.handleOnChangeZipcode({
        target: {
          value: this.props.value.zipcode || ''
        }
      });
    }
  }

  onChange = (upd) => {
    if (this.props.onChange) {
      const changes = Object.assign(this.state, upd);
      this.props.onChange(changes);
    }
  };

  fetchZipcodes = (zipcode) => {
    data_fetch_api_resource(null, `geo/zipcode/${zipcode}/`).then((result) => {
      if (result && result.status === 200) {
        this.setState({cities: result.data});
      }
    });
  };

  fetchProvincies = (state) => {
    data_fetch_api_resource(null, `geo/provincies/${state}/`).then((result) => {
      if (result && result.status === 200 && result.data.length > 0) {
        const state = result.data[0];
        this.setState({state});
        this.onChange({state});
      }
    });
  };

  handleOnChangeZipcode = (value) => {
    clearTimeout(this.timeout);
    const zipcode = value.target.value;
    if (zipcode) {
      this.timeout = setTimeout(() => {
        this.fetchZipcodes(zipcode);
      }, 700);
    } else {
      this.setState({cities: []});
    }
    this.setState({zipcode});
    this.onChange({zipcode});
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleOnChangeLocalidad = (event, index, value) => {
    const selected = this.state.cities.filter((city) => city.city === event.target.value);
    if (selected.length > 0) {
      const city = selected[0];
      const state = city.ine.slice(0, 2);
      this.fetchProvincies(state);
      this.setState({city});
      this.onChange({city});
    }
  };

  handleOnChangeTipovia = (event, index, value) => {
    const tv = this.state.tipovias.filter((el => el.id === event.target.value));
    this.setState({tv: tv[0]});
    this.onChange({tv: tv[0]})
  };

  handleGenericOnChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const newState = {};
    newState[key] = value;
    this.setState(newState);
    this.onChange(newState);
  };

  renderAddress() {

    const address = this.state;

    if (_.isEmpty(address)) {
      return <div/>
    }

    return (
      <div>
        <div>{ address.tv.name } { address.nv }, { address.pnp } {address.bq} {address.es} {address.pt} {address.pu}</div>
        <div>{address.zipcode} { address.city.city } ({address.state.name})</div>
        { address.ref_catastral &&
          <div>{ i18n.t('common:text.address_ref') } { address.ref_catastral }</div>
        }
      </div>
    );
  }

  renderForm() {

    const cities = this.state.cities.map((city) => {
      return <MenuItem key={city.city} value={city.city}>{city.city}</MenuItem>
    });

    const tipovias = this.state.tipovias.map((tipovia) => {
      return <MenuItem key={tipovia.id} value={tipovia.id}>{tipovia.name}</MenuItem>
    });

    const {errors} = this.props;

    return (
      <div>
        {(this.props.showMobile || this.props.showEmail) &&
          <>
            {!this.props.label &&
              <Typography variant="subtitle1" style={{marginTop: 20}}>
                {i18n.t('common:text.contractation_contact_details')}
              </Typography>
            }
            <Grid container spacing={3}>
              {this.props.showMobile && (
                <Grid item xs={12} sm={6} >
                  <TextField
                    label={i18n.t('common:text.address_phone')}
                    style={{ width: "100%" }}
                    name="mobile"
                    onChange={this.handleGenericOnChange}
                    value={this.state.mobile}
                    error={!!(errors.mobile)}
                  />
                  {errors && errors.mobile && (
                    <FormHelperText error={true}>{errors.mobile}</FormHelperText>
                  )}
                </Grid>
              )}
              {this.props.showEmail && (
                <Grid item xs={12} sm={6}>
                  <div>
                    <TextField
                      label={i18n.t('common:text.address_email')}
                      style={{ width: "100%" }}
                      name="email"
                      onChange={this.handleGenericOnChange}
                      value={this.state.email}
                      error={!!(errors.email)}
                    />
                    {errors && errors.email && (
                      <FormHelperText error={true}>{errors.email}</FormHelperText>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </>
        }
        {this.props.showName && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={i18n.t('common:text.address_name')}
                name="name"
                fullWidth
                onChange={this.handleGenericOnChange}
                value={this.state.name}
                error={!!errors.name}
              />
              {errors && errors.name && (
                <FormHelperText error={true}>{errors.name}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )}
        <Divider style={{marginBottom: 20, marginTop: 20}}/>
        { !this.props.label &&
        <Typography variant="subtitle1">
          {i18n.t('common:text.contractation_address')}
        </Typography>
        }
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
              label={i18n.t('common:text.address_postal_code')}
              style={{ width: "100%" }}
              onChange={this.handleOnChangeZipcode}
              value={this.state.zipcode}
              error={!!errors.zipcode}
            />
            {errors && errors.zipcode && (
              <FormHelperText error={true}>{errors.zipcode}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={5}>
            <FormControl
              disabled={!this.state.zipcode}
              style={{ width: "100%" }}
            >
              <InputLabel shrink htmlFor="">
                {i18n.t('common:text.address_locality')}
              </InputLabel>
              <Select
                onChange={this.handleOnChangeLocalidad}
                value={this.state.city.city ? this.state.city.city : ""}
                error={!!(errors && errors.city)}
                autoWidth={true}
              >
                {cities}
              </Select>
            </FormControl>
            {errors && errors.city && (
              <FormHelperText error={true}>{errors.city}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <FormControl
              disabled={true}
              style={{ width: "100%" }}
            >
              <InputLabel shrink htmlFor="">
                {i18n.t('common:text.address_province')}
              </InputLabel>
              <Select
                onChange={this.handleOnChangeLocalidad}
                value={this.state.state.id ? this.state.state.id : ""}
                autoWidth={true}
                error={!!(errors && errors.state)}
              >
                {this.state.state && (
                  <MenuItem
                    key={this.state.state.id}
                    value={this.state.state.id}
                  >
                    {this.state.state.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {errors && errors.state && (
              <FormHelperText error={true}>{errors.state}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              disabled={!this.state.tipovias.length}
              style={{ width: "100%" }}
            >
              <InputLabel shrink htmlFor="">
                {i18n.t('common:text.address_street_type')}
              </InputLabel>
              <Select
                onChange={this.handleOnChangeTipovia}
                value={this.state.tv.id ? this.state.tv.id : ""}
                error={!!(errors && errors.tv)}
                autoWidth={true}
              >
                {tipovias}
              </Select>
            </FormControl>
            {errors && errors.tv && (
              <FormHelperText error={true}>{errors.tv}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={10} md={7}>
            <TextField
              label={i18n.t('common:text.address_street_name')}
              style={{ width: "100%" }}
              onChange={this.handleGenericOnChange}
              name="nv"
              value={this.state.nv}
              error={!!(errors.nv)}
            />
            {errors && errors.nv && (
              <FormHelperText error={true}>{errors.nv}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              label={i18n.t('common:text.address_street_number')}
              style={{ width: "100%" }}
              name="pnp"
              onChange={this.handleGenericOnChange}
              value={this.state.pnp}
              error={!!(errors.pnp)}
            />
            {errors && errors.pnp && (
              <FormHelperText error={true}>{errors.pnp}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label={i18n.t('common:text.address_block')}
              style={{ width: "100%" }}
              name="bq"
              onChange={this.handleGenericOnChange}
              value={this.state.bq}
              error={!!(errors.bq)}
            />
            {errors && errors.bq && (
              <FormHelperText error={true}>{errors.bq}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label={i18n.t('common:text.address_stairs')}
              style={{ width: "100%" }}
              name="es"
              onChange={this.handleGenericOnChange}
              value={this.state.es}
              error={!!(errors.es)}
            />
            {errors && errors.es && (
              <FormHelperText error={true}>{errors.es}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label={i18n.t('common:text.address_floor')}
              style={{ width: "100%" }}
              name="pt"
              onChange={this.handleGenericOnChange}
              value={this.state.pt}
              error={!!(errors.pt)}
            />
            {errors && errors.pt && (
              <FormHelperText error={true}>{errors.pt}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label={i18n.t('common:text.address_door')}
              style={{ width: "100%" }}
              name="pu"
              onChange={this.handleGenericOnChange}
              value={this.state.pu}
              error={!!(errors.pu)}
            />
            {errors && errors.pu && (
              <FormHelperText error={true}>{errors.pu}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={i18n.t('common:text.address_ref')}
              style={{ width: "100%" }}
              name="ref_catastral"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "").toUpperCase();
                this.handleGenericOnChange(e);
              }}
              value={this.state.ref_catastral}
              error={!!(errors.ref_catastral)}
            />
            {errors && errors.ref_catastral && (
              <FormHelperText error={true}>{errors.ref_catastral}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={i18n.t('common:text.address_hint')}
              style={{ width: "100%" }}
              name="aclarador"
              onChange={this.handleGenericOnChange}
              value={this.state.aclarador}
              error={!!(errors.aclarador)}
              variant="filled"
              minRows={2}
              multiline
            />
            {errors && errors.aclarador && (
              <FormHelperText error={true}>{errors.aclarador}</FormHelperText>
            )}
            <div
              className="helper-text" 
              style={{ width: "100%", marginTop: 5 }}
            >
              {i18n.t('common:text.address_hint_helper')}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {

    return (
      <div style={this.props.style}>
        {this.props.label &&
          <Box>
            <Typography variant="h6">
              {this.props.label}
            </Typography>
          </Box>
        }
        {!this.props.readonly && this.renderForm()}
        {this.props.readonly && this.renderAddress()}

      </div>
    );
  }
}

export default Address;


Address.defaultProps = {
  errors: {},
  label: '',
  readonly: false
};
