import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material"
import _ from "lodash";
import { i18n } from "../../config";
import { LoadingAnimation } from "../LoadingAnimation";
import RichContent from "../RichContent";


const Confirmed = ({onGoBack}) => {
  const newElectricityContract = useSelector((state) => state.newElectricityContract);

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  return (
    <div>
      {_.get(newElectricityContract, "confirmResponse.result") ? (
        <RichContent
          content={newElectricityContract.confirmResponse.result.render.body}
          html={newElectricityContract.confirmResponse.result.render.format === "html"}
        />
      ) : (
        <LoadingAnimation />
      )}
      {_.get(newElectricityContract, "confirmResponse.result.error") && (
        <Button
          variant={'text'}
          onClick={handleGoBack}
          style={{ marginRight: 12 }}
        >
          {i18n.t('common:text.contractation_previous')}
        </Button>
      )}
      {_.get(newElectricityContract, "confirmResponse.status") === "OK" && (
        <div>
          {props.company ? i18n.t('common:text.contractation_form_sent_business') : i18n.t('common:text.contractation_form_sent')}
        </div>
      )}
    </div>
  );
};

export default Confirmed;
